import React, { useCallback, useReducer } from "react";

const PasswordValidatorContext = React.createContext();

const passwordValidatorInitialState = {
  charCountValidator: false,
  includeNumberValidator: false,
  includerLowerAndUpperCaseValidator: false,
  includeSpecialCharValidator: false,
};

const PasswordValidatorContextActions = {
  SET_VALIDATOR_ATTR_VALUE: "setValidatorAttrValue",
};

const reducer = (state, action) => {
  switch (action.type) {
    case PasswordValidatorContextActions.SET_VALIDATOR_ATTR_VALUE: {
      return { ...state, [action.name]: action.validity };
    }
    default:
      return state;
  }
};

const PasswordValidatorContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, passwordValidatorInitialState);

  const setValidatorAttrValue = useCallback(
    (name = "", validity = false) =>
      dispatch({
        type: PasswordValidatorContextActions.SET_VALIDATOR_ATTR_VALUE,
        name,
        validity,
      }),
    [dispatch]
  );

  const value = { ...state, setValidatorAttrValue };

  return (
    <PasswordValidatorContext.Provider value={value}>
      {props.children}
    </PasswordValidatorContext.Provider>
  );
};

export { PasswordValidatorContextProvider, PasswordValidatorContext };
