import React, {
  useReducer,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { getLoyaltyProfile, updatePortalAccount } from "../services";
import { KeycloakContext } from "./KeycloakContext";

const UserContext = React.createContext();

const initialState = {
  isLoadingUserProfile: false,
  isUpdatingUserProfile: false,
  _id: "",
  tier: "",
  username: "",
  points: 0,
  email: "",
  mobileNumber: "",
  cards: [],
  residentialAddress: { line1: "", city: "", stateOrProvince: "" },
  notificationPreference: {
    preferredChannel: "",
    allowPromotionalNotifications: false,
  },
  merchantLocation: { locationName: "" },
  status: "",
  type: "",
  isRequiredDataMissing: false,
  isUpdateBirthday: false,
  isUpdateAddress: false,
  isUpdateEmail: false,
  showMissingDetailsUpdateModal: false,
  dataForUpdateMissingDetailsModal: {
    birthDate: "",
    email: "",
    line1: "",
    city: "",
    stateOrProvince: "",
    preferredChannel: "",
  },
};

const UserContextActions = {
  SET_IS_LOADING_USER_PROFILE: "setIsLoadingUserProfile",
  SET_IS_UPDATING_USER_PROFILE: "setIsUpdatingUserProfile",
  SET_USER_PROFILE: "setUserProfile",
  SET_IS_REQUIRED_DATA_MISSING: "setIsRequiredDataMissing",
  SET_IS_UPDATE_ATTRIBUTE: "setIsUpdateAttribute",
  SET_SHOW_MISSING_DETAILS_UPDATE_MODAL: "setShowMissingDetailsUpdateModal",
  SET_DATA_FOR_UPDATE_MISSING_DETAILS_MODAL:
    "setDataForUpdateMissingDetailsModal",
  UPDATE_PROFILE: "updateProfile",
};

const reducer = (state, action) => {
  switch (action.type) {
    case UserContextActions.SET_IS_LOADING_USER_PROFILE: {
      return { ...state, isLoadingUserProfile: action.loadingState };
    }
    case UserContextActions.SET_IS_UPDATING_USER_PROFILE: {
      return { ...state, isUpdatingUserProfile: action.loadingState };
    }
    case UserContextActions.SET_USER_PROFILE: {
      return { ...state, ...action.userProfileResponse };
    }
    case UserContextActions.SET_IS_REQUIRED_DATA_MISSING: {
      return { ...state, isRequiredDataMissing: action.status };
    }
    case UserContextActions.SET_IS_UPDATE_ATTRIBUTE: {
      return { ...state, [action.attribute]: action.status };
    }
    case UserContextActions.SET_SHOW_MISSING_DETAILS_UPDATE_MODAL: {
      return { ...state, showMissingDetailsUpdateModal: action.modalState };
    }
    case UserContextActions.SET_DATA_FOR_UPDATE_MISSING_DETAILS_MODAL: {
      return { ...state, dataForUpdateMissingDetailsModal: { ...action.data } };
    }
    case UserContextActions.UPDATE_PROFILE: {
      const { type, tier, ...rest } = action;
      return {
        ...state,
        tier: tier || "NA",
        ...rest,
      };
    }
    default:
      return state;
  }
};

const UserContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    keycloakLogout: logout,
    login: keycloakLogin,
    authComplete,
  } = useContext(KeycloakContext);
  const { keycloak, initialized } = useKeycloak();
  const isAuth = keycloak.authenticated || false;

  const setIsUpdatingUserProfile = useCallback(
    (loadingState = false) =>
      dispatch({
        type: UserContextActions.SET_IS_UPDATING_USER_PROFILE,
        loadingState,
      }),
    [dispatch]
  );

  const setIsRequiredDataMissing = useCallback(
    (status = false) =>
      dispatch({
        type: UserContextActions.SET_IS_REQUIRED_DATA_MISSING,
        status,
      }),
    [dispatch]
  );

  const setIsUpdateAttribute = useCallback(
    (attribute = "", status = false) => {
      try {
        if (!attribute) throw new Error("'attribute' cannot be empty.");

        dispatch({
          type: UserContextActions.SET_IS_UPDATE_ATTRIBUTE,
          attribute,
          status,
        });
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            Failed to set values!
            <br />
            Please reload the page.
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      }
    },
    [dispatch]
  );

  const setShowMissingDetailsUpdateModal = useCallback(
    (modalState = false) =>
      dispatch({
        type: UserContextActions.SET_SHOW_MISSING_DETAILS_UPDATE_MODAL,
        modalState,
      }),
    [dispatch]
  );

  const setDataForUpdateMissingDetailsModal = useCallback(
    (data = {}) => {
      try {
        dispatch({
          type: UserContextActions.SET_DATA_FOR_UPDATE_MISSING_DETAILS_MODAL,
          data,
        });
      } catch (e) {
        dispatch({
          type: UserContextActions.SET_DATA_FOR_UPDATE_MISSING_DETAILS_MODAL,
          data: {},
        });
        console.error(
          "Failed to set data for update missing details modal:",
          e
        );
        toast.error(
          <div>
            Failed to set data for update missing details modal!
            <br />
            {e.error || e.message
              ? `Error: ${e.error || e.message}`
              : "Please try again later."}
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      }
    },
    [dispatch]
  );

  const loadProfile = useCallback(async () => {
    try {
      dispatch({
        type: UserContextActions.SET_IS_LOADING_USER_PROFILE,
        loadingState: true,
      });
      const userProfileResponse = await getLoyaltyProfile();
      dispatch({
        type: UserContextActions.SET_USER_PROFILE,
        userProfileResponse,
      });
      setIsRequiredDataMissing(
        !userProfileResponse?.birthDate ||
          !userProfileResponse?.residentialAddress?.city ||
          !userProfileResponse?.residentialAddress?.stateOrProvince
      );
      setIsUpdateAttribute(
        "isUpdateBirthday",
        !userProfileResponse?.birthDate ||
          !userProfileResponse?.residentialAddress?.city ||
          !userProfileResponse?.residentialAddress?.stateOrProvince
      );
      setIsUpdateAttribute(
        "isUpdateAddress",
        !userProfileResponse?.residentialAddress?.city ||
          !userProfileResponse?.residentialAddress?.stateOrProvince
      );

      setIsUpdateAttribute(
        "isUpdateEmail",
        !userProfileResponse?.email ||
          !userProfileResponse?.residentialAddress?.city ||
          !userProfileResponse?.residentialAddress?.stateOrProvince
      );
    } catch (e) {
      console.error(e);
      setIsRequiredDataMissing(false);
      setIsUpdateAttribute(false);
      toast.error(
        <div>
          Failed to load user profile!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    } finally {
      dispatch({
        type: UserContextActions.SET_IS_LOADING_USER_PROFILE,
        loadingState: false,
      });
    }
  }, [dispatch, setIsRequiredDataMissing, setIsUpdateAttribute]);

  const updatedProfile = useCallback(
    async ({ payload = {}, successMessage = "", failureMessage = "" }) => {
      try {
        if (
          payload &&
          (typeof payload !== "object" ||
            (typeof payload === "object" && Object.keys(payload).length === 0))
        )
          throw new Error("Invalid update data!");

        setIsUpdatingUserProfile(true);
        await updatePortalAccount(payload);
        setIsUpdatingUserProfile(false);
        toast.success(successMessage || "Successfully updated your account.");
        await loadProfile();
      } catch (e) {
        console.error(e);
        setIsUpdatingUserProfile(false);
        toast.error(
          <div>
            {failureMessage || `Failed to update your account`}!
            <br />
            {e.error || e.message
              ? `Error: ${e.error || e.message}`
              : "Please try again later."}
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      } finally {
        setIsUpdatingUserProfile(false);
      }
    },
    [setIsUpdatingUserProfile, loadProfile]
  );

  useEffect(() => {
    if (authComplete) {
      loadProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authComplete]);

  const value = useMemo(
    () => ({
      ...state,
      isAuth,
      initialized,
      login: keycloakLogin,
      logout,
      setIsUpdatingUserProfile,
      setIsRequiredDataMissing,
      setIsUpdateAttribute,
      setShowMissingDetailsUpdateModal,
      setDataForUpdateMissingDetailsModal,
      loadProfile,
      updatedProfile,
    }),
    [
      state,
      initialized,
      isAuth,
      keycloakLogin,
      logout,
      setIsUpdatingUserProfile,
      setIsRequiredDataMissing,
      setIsUpdateAttribute,
      setDataForUpdateMissingDetailsModal,
      setShowMissingDetailsUpdateModal,
      loadProfile,
      updatedProfile,
    ]
  );

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

export {
  UserContext,
  UserContextProvider,
  UserContextConsumer,
  UserContextActions,
};
