import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import UtilContext from "../../../contexts/UtilContext";

const AlertBox = () => {
  const { alert, toggleAlert } = useContext(UtilContext);
  const { title, body, isShow, alertType, size, customBtn1, customBtn2 } =
    alert;

  return (
    <Modal
      show={isShow}
      size={size}
      onHide={toggleAlert}
      backdrop="static"
      centered
      contentClassName="rounded"
    >
      <Modal.Header>
        <Modal.Title className={`text-${alertType}`}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        {customBtn1 || null}
        <Button
          className="mx-3 px-5 py-1 rounded"
          variant={alertType}
          size="sm"
          onClick={toggleAlert}
        >
          Close
        </Button>
        {customBtn2 || null}
      </Modal.Footer>
    </Modal>
  );
};
export default AlertBox;
