import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import {
  UserContextProvider,
  KeycloakContextProvider,
  DataContextProvider,
  UtilContextContainer,
} from "./contexts";
import AlertBox from "./components/utils/alert/AlertBox";
import LoadingSpinner from "./components/utils/loadingSpinner/LoadingSpinner";
import Portal from "./components/portal/Portal";
import ErrorBoundary from "./ErrorBoundary";
import AuthRoute from "./AuthRoute";

import "./vendor/theme/bootstrap-theme.css";
import "react-toastify/dist/ReactToastify.min.css";

const SignupPage = React.lazy(() =>
  import("./components/account/signup/SignupPage")
);
const LandingPage = React.lazy(() =>
  import("./components/landingpage/LandingPageV2")
);
const Terms = React.lazy(() => import("./components/terms/Terms"));

// TODO: Currently not in use. Remove if not needed.
// const Logout = React.lazy(() => import("./components/account/Logout"));
// const Login = React.lazy(() => import("./components/account/Login"));
// const ForgotPassword = React.lazy(() =>
//   import("./components/account/ForgotPassword")
// );
// const LoginVerify = React.lazy(() =>
//   import("./components/account/LoginOTPVerify")
// );

function App() {
  return (
    <div className="App h-100 w-100">
      <ErrorBoundary>
        <ToastContainer transition={Slide} theme="colored" />
        <Suspense fallback={<LoadingSpinner />}>
          <Router>
            <UtilContextContainer>
              <KeycloakContextProvider>
                <UserContextProvider>
                  <DataContextProvider>
                    <Suspense fallback={<LoadingSpinner />}>
                      <Routes>
                        <Route path="terms" element={<Terms />} />
                        <Route path="signup/*" element={<SignupPage />} />
                        <Route
                          path="portal/*"
                          element={
                            <AuthRoute>
                              <Portal />
                            </AuthRoute>
                          }
                        />
                        <Route path="/*" element={<LandingPage />} />
                        {/* 
                          // TODO: Currently not in use. Remove if not needed. 
                        */}
                        {/* <Route path="login" element={<Login />} /> */}
                        {/* <Route path="loginverify" element={<LoginVerify />} /> */}
                        {/* <Route
                            path="forgetpassword"
                            element={<ForgotPassword />}
                          /> */}
                        {/* <Route path="logout" element={<Logout />} /> */}
                        {/* 
                          // !TODO: Currently not in use. Remove if not needed. 
                        */}
                      </Routes>
                    </Suspense>
                    <AlertBox />
                  </DataContextProvider>
                </UserContextProvider>
              </KeycloakContextProvider>
            </UtilContextContainer>
          </Router>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
