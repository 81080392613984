const TransactionType = {
    COLLECTION: "COLLECTION",
    REDEMPTION: "REDEMPTION",
    ADJUSTMENT: "ADJUSTMENT"
};

const OperationType = {
    ADD: "ADD",
    SUBTRACT: "SUBTRACT"
};

export { TransactionType, OperationType };