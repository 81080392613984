const LOYALTY_SERVICE_URL = `${process.env.REACT_APP_API_BASE_URL}portal/`;
const PNS_SYNC_SERVICE_URL =
  process.env.REACT_APP_API_PNS_SYNC_SERVICE_BASE_URL;
const AUTH_CONFIG =
  process.env.REACT_APP_BUILD === "production"
    ? {
        realm: "pns-loyalty-portal",
        "auth-server-url": "https://idp.loyalty.cxforge.com/auth/",
        "client-id": "pns-portal",
      }
    : {
        realm: "pns-loyalty",
        "auth-server-url": "https://stagingauth.topnotch.club/auth/",
        "client-id": "pns-portal",
      };
const MARKETING_AND_PROMOTIONAL_ALERT_CONSENT_WORDING =
  process.env.REACT_APP_MARKETING_AND_PROMOTIONAL_ALERT_CONSENT_WORDING ||
  "I want to receive text message alerts with exclusive offers and promotions from P&S Family First Loyalty.";
const EMAIL_VERIFY_LIMIT = 3;

export {
  LOYALTY_SERVICE_URL,
  AUTH_CONFIG,
  PNS_SYNC_SERVICE_URL,
  MARKETING_AND_PROMOTIONAL_ALERT_CONSENT_WORDING,
  EMAIL_VERIFY_LIMIT,
};
