import React, { useState, useCallback, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { EMAIL_VERIFY_LIMIT } from "../../../Constants";
import { VALID_TRANSPORT_METHODS } from "../../../data";
import {
  sendVerificationOtpToEmail,
  sendVerificationOtpToMobileNumber,
  verifyEmailWithOtp,
  verifyMobileNumberWithOtp,
} from "../../../services";
import { replaceCountryCodeInMobileNumber } from "../../../utils";
import PnSLoadingSpinner from "../pnsLoadingSpinner/PnSLoadingSpinner";
import VerificationInput from "./verificationInput/VerificationInput";

import "./Verification.css";

const onMissingFunctionRef = () => {
  console.error("Missing function reference!");
  toast.error(
    <div>
      Invalid action!
      <br />
      <small>If the issue persists, please contact P&S.</small>
    </div>
  );
};

const Verification = ({
  usedInSignup,
  mobileNumber,
  isVerficationProcessOnGoing,
  referenceToken,
  disableResend,
  emailReferenceToken,
  transport,
  text,
  verificationText,
  otherData,
  setIsChildLoading,
  setMobileNumberSendOtpResponse,
  setEmailSendOtpResponse,
  setIsMobileNumberVerified,
  setIsEmailVerified,
  callbackAfterSuccessfulVerify,
}) => {
  const [verifyTransportData, setVerifyTransportData] = useState({
    transport: "",
    text: "",
    verificationText: null,
    otherData: {},
  });
  const [codeItem, setCodeItem] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResedingOtp, setIsResedingOtp] = useState(false);
  const location = useLocation();

  const onResendCode = useCallback(async () => {
    try {
      setIsChildLoading(true);
      setIsResedingOtp(true);
      const { referenceToken } =
        verifyTransportData.transport === VALID_TRANSPORT_METHODS.EMAIL
          ? await sendVerificationOtpToEmail({
              email: verifyTransportData.otherData?.email,
              memberId: verifyTransportData.otherData?.memberId || "",
            })
          : await sendVerificationOtpToMobileNumber({
              mobileNumber,
            });
      verifyTransportData.transport === VALID_TRANSPORT_METHODS.EMAIL
        ? setEmailSendOtpResponse(referenceToken)
        : setMobileNumberSendOtpResponse(referenceToken);
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to resend verification code!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    } finally {
      setIsChildLoading(false);
      setIsResedingOtp(false);
    }
  }, [
    verifyTransportData,
    mobileNumber,
    setIsChildLoading,
    setEmailSendOtpResponse,
    setMobileNumberSendOtpResponse,
    setIsResedingOtp,
  ]);

  const onSubmit = useCallback(
    async (event) => {
      try {
        event.preventDefault();
        event.stopPropagation();
        if (codeItem.length === 5) {
          const verifyTransportPayload = {
            ...(verifyTransportData.transport === VALID_TRANSPORT_METHODS.EMAIL
              ? {
                  email: verifyTransportData.otherData?.email,
                  memberId: verifyTransportData.otherData?.memberId || "",
                }
              : { mobileNumber }),
            referenceToken:
              verifyTransportData.transport === VALID_TRANSPORT_METHODS.EMAIL
                ? emailReferenceToken
                : referenceToken,
            code: codeItem,
          };

          setIsChildLoading(true);
          setIsVerifying(true);

          verifyTransportData.transport === VALID_TRANSPORT_METHODS.EMAIL
            ? await verifyEmailWithOtp(verifyTransportPayload)
            : await verifyMobileNumberWithOtp(verifyTransportPayload);
          verifyTransportData.transport === VALID_TRANSPORT_METHODS.EMAIL
            ? setIsEmailVerified(true, verifyTransportData.otherData?.email)
            : setIsMobileNumberVerified(true);

          setIsChildLoading(false);
          setIsVerifying(false);
          callbackAfterSuccessfulVerify();
        }
      } catch (e) {
        setIsChildLoading(false);
        setIsVerifying(false);
        console.error(e);
        toast.error(
          <div>
            Failed to verify
            {verifyTransportData.text ? " " + verifyTransportData.text : ""}!
            <br />
            {e.error || e.message
              ? `Error: ${e.error || e.message}`
              : "Please try again later."}
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      } finally {
      }
    },
    [
      codeItem,
      verifyTransportData,
      mobileNumber,
      referenceToken,
      emailReferenceToken,
      setIsChildLoading,
      setIsMobileNumberVerified,
      setIsEmailVerified,
      callbackAfterSuccessfulVerify,
      setIsVerifying,
    ]
  );

  useEffect(() => {
    if (location.state) {
      setVerifyTransportData({
        transport: location.state?.transport || "",
        text: location.state?.text || "",
        verificationText: (
          <div className="mb-0">
            {"We have sent a verification code to: "}
            <strong className="text-pns">
              {mobileNumber
                ? replaceCountryCodeInMobileNumber(mobileNumber, 2, "0")
                : "~ unknown"}
            </strong>
          </div>
        ),
        otherData: location.state?.otherData || {},
      });
    } else {
      setVerifyTransportData({
        transport: transport || "",
        text: text || "",
        verificationText: verificationText || null,
        otherData: otherData || {},
      });
    }
  }, [
    mobileNumber,
    otherData,
    text,
    transport,
    verificationText,
    location.state,
  ]);

  // * To show a warning if the user is navigating out of the page or is going to reload the page.
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? This will cancel the current verification process.";
    e.returnValue = message;
    return message;
  };

  // * To show a warning if the user is navigating out of the page or is going to reload the page.
  useEffect(() => {
    if (isVerficationProcessOnGoing) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isVerficationProcessOnGoing]);

  return (
    <Row className="my-5 justify-content-center">
      <Col xs={10} sm={10} md={10} lg={8} xl={8}>
        {isVerifying ? (
          <PnSLoadingSpinner
            className="my-3 d-flex flex-column align-items-center"
            text={`Verifying${
              verifyTransportData.text ? " " + verifyTransportData.text : ""
            }...`}
          />
        ) : (
          <Form onSubmit={onSubmit}>
            <div className="verification text-center">
              {verifyTransportData.verificationText}
              <Row className="justify-content-center">
                <Col xs={12} sm={10} md={9} lg={9} xl={8} xxl={6}>
                  <VerificationInput
                    usedInSignup={usedInSignup}
                    verifyMethod={verifyTransportData.transport}
                    codeItem={codeItem}
                    isRequestingVerifyToken={isResedingOtp}
                    disableResend={disableResend === EMAIL_VERIFY_LIMIT}
                    setCodeItem={setCodeItem}
                    onVerifyAccount={onSubmit}
                    onResendCode={onResendCode}
                  />
                </Col>
              </Row>
              {verifyTransportData.otherData?.skipVerificationIsPossible ? (
                <Button
                  className="mt-3 p-0"
                  variant="link"
                  size="sm"
                  disabled={isVerifying || isResedingOtp}
                  onClick={
                    verifyTransportData.otherData?.onSkip ||
                    onMissingFunctionRef
                  }
                >
                  Skip
                </Button>
              ) : null}
            </div>
          </Form>
        )}
      </Col>
    </Row>
  );
};

Verification.defaultProps = {
  usedInSignup: false,
  mobileNumber: "",
  isVerficationProcessOnGoing: false,
  referenceToken: "",
  disableResend: false,
  emailReferenceToken: "",
  transport: "",
  text: "",
  verificationText: null,
  otherData: {},
  setIsChildLoading: () => {},
  setMobileNumberSendOtpResponse: () => {},
  setEmailSendOtpResponse: () => {},
  setIsMobileNumberVerified: () => {},
  setIsEmailVerified: () => {},
  callbackAfterSuccessfulVerify: onMissingFunctionRef,
};

Verification.propTypes = {
  usedInSignup: PropTypes.bool,
  mobileNumber: PropTypes.string,
  isVerficationProcessOnGoing: PropTypes.bool,
  referenceToken: PropTypes.string,
  disableResend: PropTypes.bool,
  emailReferenceToken: PropTypes.string,
  transport: PropTypes.string,
  text: PropTypes.string,
  verificationText: PropTypes.any,
  otherData: PropTypes.object,
  setIsChildLoading: PropTypes.func,
  setMobileNumberSendOtpResponse: PropTypes.func,
  setEmailSendOtpResponse: PropTypes.func,
  setIsMobileNumberVerified: PropTypes.func,
  setIsEmailVerified: PropTypes.func,
  callbackAfterSuccessfulVerify: PropTypes.func,
};

export default Verification;
