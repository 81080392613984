import React, { useReducer, useCallback } from "react";
import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import LoadingSpinner from "../components/utils/loadingSpinner/LoadingSpinner";
import AppContext from "../AppContext";

const KeycloakContext = React.createContext();

const KeycloakContextActions = {
  SET_IS_AUTH: "setIsAuth",
  UPDATE_TOKEN: "setToken",
};
const initialState = {
  token: null,
  authComplete: false,
  isAuth: false,
};

const keycloakInstance = Keycloak(AppContext.keycloakConfig);

const reducer = (state, action) => {
  switch (action.type) {
    case KeycloakContextActions.SET_IS_AUTH: {
      return {
        ...state,
        isAuth: action.status,
      };
    }
    case KeycloakContextActions.UPDATE_TOKEN: {
      return {
        ...state,
        token: action.token,
        authComplete: !!action.token,
      };
    }
    default: {
      return state;
    }
  }
};

const constantMock = window.fetch;

const KeycloakContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const authEventHandler = useCallback(
    (event, error) => {
      switch (event) {
        case "onAuthError":
        case "onAuthLogout": {
          dispatch({ type: KeycloakContextActions.SET_IS_AUTH, status: false });
          break;
        }
        default:
          return null;
      }
    },
    [dispatch]
  );

  const updateFetch = useCallback((token) => {
    window.fetch = async function () {
      // * Get the parameter in arguments
      // * Intercept the parameter here
      const body = arguments[1];
      if (body.headers["x-auth"]) {
        delete body.headers["x-auth"];
        body.headers["Authorization"] = "Bearer " + token;
      }

      const response = await constantMock.apply(this, arguments);

      if (response.status === 401 || response.status === 403) {
        keycloakInstance.logout();
      }

      return response;
    };
  }, []);

  const authTokenHandler = useCallback(
    ({ token }) => {
      if (token) {
        updateFetch(token);
        dispatch({ type: KeycloakContextActions.UPDATE_TOKEN, token });
      } else {
        window.fetch = constantMock;
      }
    },
    [dispatch, updateFetch]
  );

  const value = {
    ...state,
    isAuth: keycloakInstance.authenticated || false,
    login: keycloakInstance.login,
    keycloakLogout: keycloakInstance.logout,
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      LoadingComponent={<LoadingSpinner />}
      onEvent={authEventHandler}
      onTokens={authTokenHandler}
    >
      <KeycloakContext.Provider value={value}>
        {props.children}
      </KeycloakContext.Provider>
    </ReactKeycloakProvider>
  );
};

const KeycloakContextConsumer = KeycloakContext.Consumer;

export { KeycloakContext, KeycloakContextProvider, KeycloakContextConsumer };
