const NavigationData = [
  {
    id: "nav-home",
    name: "Home",
    path: "/portal",
    icon: "/assets/icons/home.svg",
    selectedicon: "/assets/icons/home1.svg",
  },
  {
    id: "nav-rewards",
    name: "Rewards",
    path: "/portal/rewards",
    icon: "/assets/icons/gift.svg",
    selectedicon: "/assets/icons/gift1.svg",
  },
  {
    id: "nav-transactions",
    name: "Transactions",
    path: "/portal/transactions",
    icon: "/assets/icons/money.svg",
    selectedicon: "/assets/icons/money1.svg",
  },
  {
    id: "nav-about",
    name: "About",
    path: "/portal/about",
    icon: "/assets/icons/about.svg",
    selectedicon: "/assets/icons/about1.svg",
  },
  {
    id: "nav-profile",
    name: "Profile",
    path: "/portal/profile",
    icon: "/assets/icons/user.svg",
    selectedicon: "/assets/icons/user1.svg",
  },
];

export default NavigationData;
