import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { DataContext, UserContext } from "../../../contexts";
import {
  PREFERRED_CONTACT,
  PreferredContactOptions,
  VALID_TRANSPORT_METHODS,
} from "../../../data";
import {
  sendVerificationOtpToEmail,
  updatePortalAccount,
} from "../../../services";
import PnSLoadingSpinner from "../../shared/pnsLoadingSpinner/PnSLoadingSpinner";
import Verification from "../../shared/verification/Verification";
import UpdateMissingDataForm from "./UpdateMissingDataForm";

const UpdateMissingDataModal = ({ show, updateMissingDetailsData, onHide }) => {
  const {
    _id: memberId = "",
    isUpdateBirthday,
    isUpdateAddress,
    isUpdateEmail,
    loadProfile,
  } = useContext(UserContext);
  const { allSLDistricts } = useContext(DataContext);
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState(""); // TODO: [SHTT-1059]: DISCUSSION: If a given email is verified and user closes the update modal (without updating the profile), store the verified email in the browser session storage to avoid the validation token endpoint being misused.
  const [verifiedEmail, setVerifiedEmail] = useState(""); // TODO: [SHTT-1059]: DISCUSSION: If a given email is verified and user closes the update modal (without updating the profile), store the verified email in the browser session storage to avoid the validation token endpoint being misused.
  const [isEmailVerified, setIsEmailVerified] = useState(false); // TODO: [SHTT-1059]: DISCUSSION: If a given email is verified and user closes the update modal (without updating the profile), store the verified email in the browser session storage to avoid the validation token endpoint being misused.
  const [emailValidity, setEmailValidity] = useState(false);
  const [line1, setLine1] = useState(updateMissingDetailsData?.line1 || "");
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [preferredChannel, setPreferredChannel] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isUpdateProcessStarted, setIsUpdateProcessStarted] = useState(false);
  const [isSendingOtpViaEmail, setIsSendingOtpViaEmail] = useState(false);
  const [showVerificationView, setShowVerificationView] = useState(false);
  const [verificationViewData, setVerificationViewData] = useState({
    transport: "",
    text: "",
    verificationText: null,
    otherData: {},
  });
  const [emailReferenceToken, setEmailReferenceToken] = useState("");
  const [continueUpdate, setContinueUpdate] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const allDistricts = useMemo(
    () =>
      allSLDistricts
        .map((aSLD) => ({
          label: `${aSLD?.name_en || "~ unknown"}${
            aSLD?.name_si ? "/" + aSLD.name_si : ""
          }${aSLD?.name_ta ? "/" + aSLD.name_ta : ""}`,
          value: aSLD?.name_en,
          id: aSLD?.id || "",
        }))
        .sort((a, b) => a?.value.localeCompare(b?.value)),
    [allSLDistricts]
  );

  const isEmailMandatory = useMemo(
    () =>
      !!~[PREFERRED_CONTACT.EMAIL, PREFERRED_CONTACT.EMAIL_AND_MOBILE].indexOf(
        preferredChannel[0]?.value
      ),
    [preferredChannel]
  );

  const onSetEmailIsVerified = useCallback(
    (status = false, emailToVeirfy = "") => {
      setIsEmailVerified(status);
      setVerifiedEmail(emailToVeirfy);
    },
    [setIsEmailVerified, setVerifiedEmail]
  );

  const onChangeAttr = useCallback(
    (e) => {
      const attrName = e.currentTarget.name;
      const attrValue = e.currentTarget.value;

      switch (attrName) {
        case "email": {
          const trimmedEmail = attrValue.trim();
          const emailInputValidity = e.currentTarget.checkValidity();
          setEmailValidity(emailInputValidity);

          if (emailReferenceToken) {
            onSetEmailIsVerified(false, "");
            setEmailReferenceToken("");
          }

          setEmail(trimmedEmail);
          break;
        }
        case "line1": {
          setLine1(attrValue);
          break;
        }
        default:
          break;
      }
    },
    [
      emailReferenceToken,
      onSetEmailIsVerified,
      setLine1,
      setEmailValidity,
      setEmailReferenceToken,
    ]
  );

  const onChangeDate = useCallback((date) => setDob(date), [setDob]);

  const onChangeCity = useCallback((e) => setCity(e), [setCity]);

  const onChangeDistrict = useCallback(
    (e) => {
      setCity([]);
      setDistrict(e);
    },
    [setCity, setDistrict]
  );

  const onChangePreferredChanel = useCallback(
    (e) => setPreferredChannel(e),
    [setPreferredChannel]
  );

  const updateAndReloadUser = useCallback(async () => {
    try {
      let preferredChannelValueToUpdate = preferredChannel[0]?.value || "";

      if (
        preferredChannel[0]?.value !== PREFERRED_CONTACT.MOBILE &&
        !isEmailVerified
      )
        preferredChannelValueToUpdate = PREFERRED_CONTACT.MOBILE;

      const updatePayload = {
        memberId,
        ...(isUpdateEmail && isEmailVerified && email
          ? { email, isEmailVerified }
          : {}),
        ...(isUpdateBirthday ? { birthDate: dob } : {}),
        ...(isUpdateAddress
          ? {
              residentialAddress: {
                ...(line1 ? { line1 } : {}),
                city: city[0]?.value || "",
                stateOrProvince: district[0]?.value || "",
              },
            }
          : {}),
        ...(preferredChannelValueToUpdate
          ? {
              notificationPreference: {
                ...(updateMissingDetailsData?.hasOwnProperty(
                  "allowPromotionalNotifications"
                )
                  ? {
                      allowPromotionalNotifications:
                        updateMissingDetailsData.allowPromotionalNotifications,
                    }
                  : { allowPromotionalNotifications: false }),
                preferredChannel: preferredChannelValueToUpdate,
              },
            }
          : {}),
      };

      setDisableFields(true);
      setIsUpdating(true);
      await updatePortalAccount(updatePayload);
      setIsUpdating(false);
      setDisableFields(false);
      setIsUpdateProcessStarted(false);
      setContinueUpdate(false);
      onHide(null, "Updated");
      loadProfile();
    } catch (e) {
      console.error(e);
      setDisableFields(false);
      setIsUpdating(false);
      setIsUpdateProcessStarted(false);
      setContinueUpdate(false);
      toast.error(
        <div>
          Failed to update your profile!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    }
  }, [
    memberId,
    isEmailVerified,
    isUpdateBirthday,
    isUpdateAddress,
    isUpdateEmail,
    email,
    dob,
    line1,
    city,
    district,
    preferredChannel,
    updateMissingDetailsData,
    onHide,
    loadProfile,
    setDisableFields,
    setIsUpdating,
    setIsUpdateProcessStarted,
    setContinueUpdate,
  ]);

  const onSkip = useCallback(() => {
    if (!isUpdateBirthday && !isUpdateAddress && isUpdateEmail) {
      setShowVerificationView(false);
      onHide();
    } else {
      setShowVerificationView(false);
      setContinueUpdate(true);
    }
  }, [
    isUpdateAddress,
    isUpdateBirthday,
    isUpdateEmail,
    onHide,
    setShowVerificationView,
    setContinueUpdate,
  ]);

  const onUpdateAfterEmailIsVerified = useCallback(() => {
    setShowVerificationView(false);
    setContinueUpdate(true);
  }, [setShowVerificationView, setContinueUpdate]);

  const sendEmailVerificationAndChangeToVerifyWindow = useCallback(async () => {
    try {
      setDisableFields(true);
      setIsSendingOtpViaEmail(true);
      const { referenceToken } = await sendVerificationOtpToEmail({
        email,
        memberId,
      });
      setEmailReferenceToken(referenceToken);
      setDisableFields(false);
      setIsSendingOtpViaEmail(false);
      setShowVerificationView(true);
      setVerificationViewData({
        transport: VALID_TRANSPORT_METHODS.EMAIL,
        text: "email",
        verificationText: (
          <div className="mb-0">
            {"An email has been sent to verify your email address: "}
            <strong className="text-primary">{email || "~ unknown"}</strong>
            {". Please check your inbox and enter the code you received."}
          </div>
        ),
        otherData: {
          email,
          memberId,
          skipVerificationIsPossible: true,
          onSkip,
        },
      });
    } catch (e) {
      console.error(e);
      setContinueUpdate(false);
      setDisableFields(false);
      setIsSendingOtpViaEmail(false);
      setEmailReferenceToken("");
      setShowVerificationView(false);
      setVerificationViewData({
        transport: "",
        text: "",
        verificationText: null,
        otherData: {},
      });
      toast.error(
        <div>
          Failed to send verification code!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    }
  }, [
    email,
    memberId,
    onSkip,
    setContinueUpdate,
    setDisableFields,
    setIsSendingOtpViaEmail,
    setEmailReferenceToken,
    setShowVerificationView,
    setVerificationViewData,
  ]);

  const onSubmitUpdates = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const form = e.currentTarget;

      if (!form.checkValidity() || (isUpdateBirthday && !dob)) {
        toast.error(
          <div>
            Empty or invalid field(s), please check again.
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      } else {
        setIsUpdateProcessStarted(true);

        if (email && !isEmailVerified && !verifiedEmail) {
          if (emailReferenceToken) {
            // * Skip sending code via email and show the verification view.
            setShowVerificationView(true);
          } else {
            await sendEmailVerificationAndChangeToVerifyWindow();
          }
        } else {
          await updateAndReloadUser();
        }
      }
      setValidated(true);
    },
    [
      isUpdateBirthday,
      dob,
      email,
      isEmailVerified,
      verifiedEmail,
      emailReferenceToken,
      sendEmailVerificationAndChangeToVerifyWindow,
      updateAndReloadUser,
      setIsUpdateProcessStarted,
      setShowVerificationView,
    ]
  );

  useEffect(() => {
    try {
      if (isUpdateBirthday && updateMissingDetailsData?.birthDate) {
        if (!moment(updateMissingDetailsData.birthDate).isValid())
          throw new Error("Invalid date!");

        setDob(moment(updateMissingDetailsData.birthDate).toDate());
      }
    } catch (e) {
      console.error(e);
      setDob("");
      toast.warning(
        "Failed to setup some initial form values but, you can continue to update the form."
      );
    }
  }, [isUpdateBirthday, updateMissingDetailsData]);

  useEffect(() => {
    const selectedPreferredContact = PreferredContactOptions.filter(
      (pCO) => pCO.value === updateMissingDetailsData?.preferredChannel
    );
    if (selectedPreferredContact.length !== 0) {
      setPreferredChannel(selectedPreferredContact);
    } else {
      setPreferredChannel(
        PreferredContactOptions.filter(
          (pCO) => pCO.value === PREFERRED_CONTACT.MOBILE
        )
      );
    }
  }, [updateMissingDetailsData]);

  useEffect(() => {
    if (isUpdateAddress && updateMissingDetailsData.stateOrProvince) {
      const existingMemberDistrict = allDistricts.filter(
        (aD) => aD?.value === updateMissingDetailsData.stateOrProvince
      );
      setDistrict(existingMemberDistrict);
    }
  }, [isUpdateAddress, allDistricts, updateMissingDetailsData]);

  useEffect(() => {
    try {
      if (isUpdateEmail) {
        if (updateMissingDetailsData.email) {
          const exisitngEmail = document.forms[0]?.["email"];
          setEmailValidity(!!exisitngEmail?.checkValidity());
        }
        setEmail(updateMissingDetailsData?.email || ""); // TODO: Add "verifiedButNotSavedEmail" from browser storage as second default value.
      }
    } catch (e) {
      console.error(e);
      setEmail("");
      toast.warning(
        "Failed to setup some initial form values but, you can continue to update the form."
      );
    }
  }, [isUpdateEmail, updateMissingDetailsData]);

  useEffect(() => {
    if (!showVerificationView && continueUpdate) {
      updateAndReloadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVerificationView, continueUpdate]);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title className="font-weight-bold">
          {showVerificationView ? "Verify Your Email" : "Update Your Profile"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUpdating && !isSendingOtpViaEmail && (
          <>
            {isEmailVerified && verifiedEmail && (
              <div className="pt-3 pb-4 text-primary text-center">
                <h5 className="font-weight-bold">
                  <FontAwesomeIcon
                    className="text-success mb-3"
                    size="2x"
                    icon={faCheckCircle}
                  />
                  <div className="mt-3">Email has been verified.</div>
                </h5>
              </div>
            )}
            <PnSLoadingSpinner
              className="mb-4 d-flex flex-column align-items-center text-center text-primary"
              text="Updating your profile. Please wait..."
            />
          </>
        )}
        {!isUpdating && isSendingOtpViaEmail && (
          <div className="pt-4 pb-5">
            <PnSLoadingSpinner
              className="my-3 d-flex flex-column align-items-center text-center text-primary"
              text="Sending an email verification code..."
            />
          </div>
        )}
        {!isUpdating && !isSendingOtpViaEmail && (
          <>
            {showVerificationView ? (
              <Verification
                isVerficationProcessOnGoing={isUpdateProcessStarted}
                emailReferenceToken={emailReferenceToken}
                {...verificationViewData}
                setEmailSendOtpResponse={setEmailReferenceToken}
                setIsEmailVerified={onSetEmailIsVerified}
                callbackAfterSuccessfulVerify={onUpdateAfterEmailIsVerified}
              />
            ) : (
              <UpdateMissingDataForm
                validated={validated}
                disableFields={disableFields}
                isUpdating={isUpdating}
                dob={dob}
                preferredChannel={preferredChannel}
                isEmailMandatory={isEmailMandatory}
                email={email}
                emailValidity={emailValidity}
                isEmailVerified={isEmailVerified}
                verifiedEmail={verifiedEmail}
                line1={line1}
                allDistricts={allDistricts}
                district={district}
                existingMemberCity={updateMissingDetailsData?.city || ""}
                city={city}
                setDisableFields={setDisableFields}
                onSubmitUpdates={onSubmitUpdates}
                onChangeAttr={onChangeAttr}
                onChangeDate={onChangeDate}
                onChangePreferredChanel={onChangePreferredChanel}
                onChangeDistrict={onChangeDistrict}
                onChangeCity={onChangeCity}
                onHide={onHide}
              />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

UpdateMissingDataModal.defaultProps = {
  show: false,
  updateMissingDetailsData: {},
  onHide: () => {},
};

UpdateMissingDataModal.propTypes = {
  show: PropTypes.bool,
  updateMissingDetailsData: PropTypes.object,
  onHide: PropTypes.func,
};

export default UpdateMissingDataModal;
