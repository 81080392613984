import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import { secondsToMinuteAndSeconds } from "../../../../utils";

const VerifcationCodeLength = new Array(5).fill(0);
const resendTimeoutInSeconds = 180;
let resendEnableTimer;

const VerificationInput = ({
  usedInSignup,
  verifyMethod,
  codeItem,
  isRequestingVerifyToken,
  disableResend,
  setCodeItem,
  onVerifyAccount,
  onResendCode,
}) => {
  const inputRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [resendTimeInSeconds, setResendTimeInSeconds] = useState(
    resendTimeoutInSeconds
  );
  const code = useMemo(() => codeItem.split(""), [codeItem]);

  const selectedCodeItem = useMemo(
    () =>
      code.length < VerifcationCodeLength.length
        ? code.length
        : VerifcationCodeLength.length - 1,
    [code]
  );

  const onClickCode = useCallback(() => inputRef.current.focus(), []);
  const onCodeFocus = useCallback(() => setIsFocused(true), [setIsFocused]);
  const onCodeBlur = useCallback(() => setIsFocused(false), [setIsFocused]);

  const onCodeKeyUp = useCallback(
    (e) =>
      (e.key === "Backspace" || e.key === "Delete") &&
      setCodeItem(codeItem.slice(0, codeItem.length - 1)),
    [codeItem, setCodeItem]
  );

  const onChangeCodeItem = useCallback(
    (e) => {
      const codeItemValue = e.target.value;
      if (codeItem.length >= VerifcationCodeLength.length) {
        return null;
      } else {
        setCodeItem(
          (codeItem + codeItemValue).slice(0, VerifcationCodeLength.length)
        );
      }
    },
    [codeItem, setCodeItem]
  );

  const onClickResend = useCallback(async () => {
    await onResendCode();
    setResendTimeInSeconds(resendTimeoutInSeconds);
  }, [onResendCode, setResendTimeInSeconds]);

  useEffect(() => {
    if (resendEnableTimer) {
      clearTimeout(resendEnableTimer);
    }

    let lastSavedTime = localStorage.getItem(`resendTime${verifyMethod}`);
    if (lastSavedTime) {
      setResendTimeInSeconds(Number(lastSavedTime));
    } else {
      setResendTimeInSeconds(resendTimeoutInSeconds);
    }
  }, [verifyMethod]);

  useEffect(() => {
    resendEnableTimer = setTimeout(() => {
      clearTimeout(resendEnableTimer);
      if (resendTimeInSeconds > 0) {
        setResendTimeInSeconds(resendTimeInSeconds - 1);
        localStorage.setItem(
          `resendTime${verifyMethod}`,
          String(resendTimeInSeconds - 1)
        );
      } else {
        localStorage.removeItem(`resendTime${verifyMethod}`);
      }
    }, 1000);

    return () => {
      if (resendEnableTimer) clearTimeout(resendEnableTimer);
    };
  }, [resendTimeInSeconds, verifyMethod]);

  return (
    <>
      <div className="mt-5" onClick={onClickCode}>
        <div className="verification wrap-verification-code px-1">
          <FormControl
            ref={inputRef}
            type="number"
            value=""
            disabled={isRequestingVerifyToken}
            onFocus={onCodeFocus}
            onBlur={onCodeBlur}
            onKeyUp={onCodeKeyUp}
            onChange={onChangeCodeItem}
            className="verification hidden-input shadow-none"
            style={{
              width: "2.5rem",
              top: "0rem",
              bottom: "0rem",
              left: `${selectedCodeItem * 3.6}rem`,
              opacity: 0,
            }}
          />
          {VerifcationCodeLength.map((c, index) => {
            const selectedInputBox = code.length === index;
            const filled =
              code.length === VerifcationCodeLength.length &&
              index === VerifcationCodeLength.length - 1;

            return (
              <div className="verification individual-code" key={`c-${index}`}>
                {code[index]}
                {(selectedInputBox || filled) && isFocused && (
                  <div className="verification individual-code-shadows" />
                )}
              </div>
            );
          })}
        </div>
        <div className="mt-4">
          <Button
            className="mx-3 px-5 py-1 rounded"
            variant={usedInSignup ? "pns" : "primary"}
            disabled={code.length < 5 || isRequestingVerifyToken}
            onClick={onVerifyAccount}
          >
            Verify
          </Button>
        </div>
      </div>
      <div className="mt-5">
        <div>
          <small>Didn't receive a code?</small>
        </div>
        <Button
          variant="link"
          size="sm"
          disabled={
            resendTimeInSeconds > 0 || isRequestingVerifyToken || disableResend
          }
          onClick={onClickResend}
        >
          {resendTimeInSeconds > 0 ? (
            `Enabled in ${secondsToMinuteAndSeconds(resendTimeInSeconds)}`
          ) : (
            <>
              {resendTimeInSeconds === 0 && isRequestingVerifyToken
                ? "Resending... "
                : "Resend "}
            </>
          )}
        </Button>
      </div>
    </>
  );
};

VerificationInput.defaultProps = {
  usedInSignup: false,
  verifyMethod: "",
  codeItem: "",
  isRequestingVerifyToken: false,
  disableResend: false,
  setCodeItem: () => {},
  onVerifyAccount: () => {},
  onResendCode: () => {},
};

VerificationInput.propTypes = {
  usedInSignup: PropTypes.bool,
  verifyMethod: PropTypes.string,
  codeItem: PropTypes.string,
  isRequestingVerifyToken: PropTypes.bool,
  disableResend: PropTypes.bool,
  setCodeItem: PropTypes.func,
  onVerifyAccount: PropTypes.func,
  onResendCode: PropTypes.func,
};

export default VerificationInput;
