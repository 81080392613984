import React, { Suspense, useCallback, useContext, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Routes, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../contexts";
import LoadingSpinner from "./../utils/loadingSpinner/LoadingSpinner";
import PortalHeader from "./portalHeader/PortalHeader";
import PortalMainNavbar from "./../navbars/PortalMainNavbar";
import UpdateMissingDataModal from "./updateMissingData/UpdateMissingDataModal";
import { MemberStatus } from "../../data";

const Home = React.lazy(() => import("./../home/Home"));
const RewardsTab = React.lazy(() => import("./../rewards/RewardsTab"));
const Transactions = React.lazy(() => import("./../transactions/Transactions"));
const About = React.lazy(() => import("./../about/About"));
const Profile = React.lazy(() => import("./../profile/Profile"));
// TODO: Currently not in use. Remove if not needed.
// const Announcements = React.lazy(() =>
//   import("./../announcements/Announcements")
// );

const Portal = () => {
  const {
    isLoadingUserProfile,
    isUpdatingUserProfile,
    isRequiredDataMissing = false,
    isUpdateEmail,
    showMissingDetailsUpdateModal,
    status = "",
    birthDate = "",
    email = "",
    residentialAddress,
    notificationPreference,
    dataForUpdateMissingDetailsModal,
    setShowMissingDetailsUpdateModal,
    setDataForUpdateMissingDetailsModal,
  } = useContext(UserContext);

  const onShowUpdateMissingDetailsModal = useCallback(
    () => setShowMissingDetailsUpdateModal(true),
    [setShowMissingDetailsUpdateModal]
  );

  const onHideUpdateMissingDetailsModal = useCallback(
    (e, data) => {
      if (data) {
        setDataForUpdateMissingDetailsModal({});
      }
      setShowMissingDetailsUpdateModal(false);
    },
    [setShowMissingDetailsUpdateModal, setDataForUpdateMissingDetailsModal]
  );

  // TODO: [SHTT-1058]: Only automatically show the update user details popup one time by utilising the browser session storage.
  useEffect(() => {
    if (
      status === MemberStatus.ACTIVE &&
      !isLoadingUserProfile &&
      (isRequiredDataMissing || isUpdateEmail)
    ) {
      onShowUpdateMissingDetailsModal();
      setDataForUpdateMissingDetailsModal({
        birthDate,
        email,
        line1: `${residentialAddress?.line1 || ""}${
          residentialAddress?.line2 ? " " + residentialAddress.line2 : ""
        }${residentialAddress?.line3 ? " " + residentialAddress.line3 : ""}`,
        city: residentialAddress?.city || "",
        stateOrProvince: residentialAddress?.stateOrProvince || "",
        ...notificationPreference,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingUserProfile,
    isRequiredDataMissing,
    isUpdateEmail,
    birthDate,
    email,
    notificationPreference,
    residentialAddress,
  ]);

  return (
    <div id="portal-view">
      <PortalHeader />
      <div className="d-flex flex-row flex-grow-1">
        <div className="mt-5">
          <PortalMainNavbar />
        </div>
        <div className="h-100 py-4 pl-lg-4 pl-0 w-100">
          <div className="pt-4 main-card w-100 h-100">
            {!isLoadingUserProfile &&
              status === MemberStatus.ACTIVE &&
              (isRequiredDataMissing || isUpdateEmail) && (
                <Card className="mx-3 mb-4 d-flex justify-content-center onload-opacity rounded-21 pns-card">
                  <Card.Body>
                    <Row className="justify-content-between">
                      <Col
                        className="d-flex justify-content-center"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={9}
                      >
                        <div className="d-flex align-items-center font-weight-bold text-primary">
                          <FontAwesomeIcon
                            className="mr-3"
                            size="lg"
                            icon={faBell}
                          />
                          {`We kindly request you to update your email address${
                            isRequiredDataMissing ? " and profile details" : ""
                          }, so we can provide you with tailored updates.`}
                        </div>
                      </Col>
                      <Col
                        className="d-flex justify-content-center update-details-btn"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={3}
                      >
                        <Button
                          className="px-5 py-1 rounded"
                          variant="primary"
                          onClick={onShowUpdateMissingDetailsModal}
                        >
                          Update Profile
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}
            {!isLoadingUserProfile && status !== MemberStatus.ACTIVE && (
              <Card className="mx-3 mb-4 text-center onload-opacity rounded-21 pns-suspended-card">
                <Card.Body>
                  <h3 className="m-0 font-weight-bold">
                    Your account is {status || "UNKNOWN"}!
                  </h3>
                </Card.Body>
              </Card>
            )}
            <Card.Body className="p-lg-5 p-3">
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  <Route>
                    <Route name="Home" index element={<Home />} />
                    <Route
                      name="Rewards"
                      path="rewards"
                      element={<RewardsTab />}
                    />
                    <Route
                      name="Transactions"
                      path="transactions"
                      element={<Transactions />}
                    />
                    <Route name="About" path="about" element={<About />} />
                    <Route
                      name="Profile"
                      path="profile"
                      element={<Profile />}
                    />
                    {/*
                      // TODO: Currently not in use. Remove if not needed. 
                    */}
                    {/* <Route name="Announcements" path="announcements" element={<Announcements />} /> */}
                  </Route>
                </Routes>
              </Suspense>
            </Card.Body>
            <Card.Footer className="text-center">
              <small>
                <Link
                  className={`text-link ${
                    isLoadingUserProfile || isUpdatingUserProfile
                      ? "disable-link"
                      : ""
                  }`}
                  to="/terms"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms & Conditions
                </Link>
              </small>
            </Card.Footer>
          </div>
        </div>
      </div>
      {status === MemberStatus.ACTIVE && showMissingDetailsUpdateModal && (
        <UpdateMissingDataModal
          show={showMissingDetailsUpdateModal}
          onHide={onHideUpdateMissingDetailsModal}
          updateMissingDetailsData={dataForUpdateMissingDetailsModal}
        />
      )}
    </div>
  );
};

export default Portal;
