import { toTitleCase } from "../utils";

const GENDER_TYPES = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHER: "OTHER",
};

const VALID_TRANSPORT_METHODS = {
  MOBILE: "MOBILE",
  EMAIL: "EMAIL",
};

const PREFERRED_CONTACT = {
  MOBILE: "MOBILE",
  EMAIL: "EMAIL",
  EMAIL_AND_MOBILE: "EMAIL_AND_MOBILE",
};

const GenderTypeOptions = Object.keys(GENDER_TYPES).map((gT) => ({
  label: toTitleCase(gT),
  value: gT,
}));

const PreferredContactOptions = Object.keys(PREFERRED_CONTACT).map((pC) => ({
  label: toTitleCase(pC),
  value: pC,
}));

export {
  GENDER_TYPES,
  VALID_TRANSPORT_METHODS,
  PREFERRED_CONTACT,
  GenderTypeOptions,
  PreferredContactOptions,
};
