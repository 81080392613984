
window.dataLayer = window.dataLayer || [];

const trackUserProperty = async (key, value) => {

    try {
        if (value) {
            window.dataLayer.push({
                event: key,
                [key]: value
            });
            // console.debug("Logging event:", key, value);
            // await analytics().logEvent(key, { [key]: value });
            // await analytics().setUserProperties({ [key]: value });
        }

    } catch (e) {
        console.error(e);
    }



}

const trackUserActivity=async(event,key,value)=>{
    try {
        if (value) {
            window.dataLayer.push({
                event: event,
                [key]: value
            });
            // console.debug("Logging event:", key, value);
            // await analytics().logEvent(key, { [key]: value });
            // await analytics().setUserProperties({ [key]: value });
        }

    } catch (e) {
        console.error(e);
    }
}


export { trackUserProperty ,trackUserActivity};