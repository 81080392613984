import React, { useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../contexts";
import { PreferredContactOptions } from "../../../data";
import {
  CitiesOfDistrictSelector,
  DistrictSelector,
} from "../../shared/formAddress/FormAddress";
import FormDate from "../../shared/formDate/FormDate";
import FormSelect from "../../shared/formSelect/FormSelect";

const UpdateMissingDataForm = ({
  validated,
  disableFields,
  isUpdating,
  dob,
  preferredChannel,
  isEmailMandatory,
  email,
  emailValidity,
  isEmailVerified,
  verifiedEmail,
  line1,
  allDistricts,
  district,
  existingMemberCity,
  city,
  setDisableFields,
  onSubmitUpdates,
  onChangeAttr,
  onChangeDate,
  onChangePreferredChanel,
  onChangeDistrict,
  onChangeCity,
  onHide,
}) => {
  const { isUpdateBirthday, isUpdateAddress, isUpdateEmail } =
    useContext(UserContext);

  return (
    <>
      <div className="px-3 py-2 mb-3 d-flex justify-content-center rounded pns-card font-weight-bold text-primary text-center">
        To enhance our services and to ensure we're keeping you informed about
        updates or special offers, we kindly ask you to provide us with your
        information.
      </div>
      <Form noValidate validated={validated} onSubmit={onSubmitUpdates}>
        <Row className="px-3 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {isUpdateBirthday && (
              <Form.Group className="m-0">
                <Row>
                  <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Label>
                      Date of Birth
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <FormDate
                      className={`border rounded ${
                        validated ? (dob ? "input-valid" : "input-invalid") : ""
                      }`}
                      selectText="Select your birthday..."
                      date={dob}
                      disabled={isUpdating || disableFields}
                      onChange={onChangeDate}
                      maxDate={moment().toDate()}
                      required
                    />
                    {validated && !dob ? (
                      <small className="text-danger">
                        Date of birth cannot be empty!
                      </small>
                    ) : null}
                    <Form.Text>
                      <i className="text-orange font-weight-bold">
                        Your birthday can ONLY be updated once.
                      </i>
                    </Form.Text>
                  </Col>
                </Row>
              </Form.Group>
            )}
            {isUpdateEmail && (
              <Form.Group className="m-0">
                <Row>
                  <Col className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Form.Label>
                      Preferred Contact
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <FormSelect
                      id="preferredChannel"
                      placeholder="Select your preferred channel..."
                      options={PreferredContactOptions}
                      selected={preferredChannel}
                      onChange={onChangePreferredChanel}
                      disabled={isUpdating || disableFields}
                      required
                    />
                    {validated && preferredChannel.length === 0 ? (
                      <small className="text-danger">
                        Preferred channel cannot be empty!
                      </small>
                    ) : null}
                  </Col>
                  <Col className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Form.Label>
                      Email
                      {isEmailMandatory ? (
                        <span className="text-danger">*</span>
                      ) : null}
                    </Form.Label>
                    <Form.Control
                      id="email"
                      className="border rounded"
                      type="email"
                      placeholder="Enter your email..."
                      name="email"
                      value={email}
                      disabled={
                        isUpdating ||
                        disableFields ||
                        (isEmailVerified && verifiedEmail)
                      }
                      onChange={onChangeAttr}
                      required={isEmailMandatory}
                    />
                    {validated && isEmailMandatory && !email ? (
                      <small className="text-danger">
                        Email cannot be empty!
                      </small>
                    ) : null}
                    {validated && email && !emailValidity ? (
                      <small className="text-danger">Email is invalid!</small>
                    ) : null}
                    {isEmailVerified && verifiedEmail ? (
                      <Form.Text className="text-success d-flex align-items-center">
                        <i>Email is verified</i>
                        <FontAwesomeIcon
                          className="ml-2"
                          size="sm"
                          icon={faCheckCircle}
                        />
                      </Form.Text>
                    ) : null}
                  </Col>
                </Row>
              </Form.Group>
            )}
            {isUpdateAddress && (
              <Form.Group className="m-0">
                <Row>
                  <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      className="border rounded"
                      type="text"
                      placeholder="Enter your address..."
                      name="line1"
                      value={line1}
                      disabled={isUpdating || disableFields}
                      onChange={onChangeAttr}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DistrictSelector
                      validated={validated}
                      disabled={isUpdating || disableFields}
                      allDistricts={allDistricts}
                      selectedDistrict={district}
                      setIsChildLoading={setDisableFields}
                      onChangeDistrict={onChangeDistrict}
                      required
                    />
                  </Col>
                </Row>
                {district.length !== 0 && district[0]?.value && (
                  <Row>
                    <Col
                      className="mt-3"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <CitiesOfDistrictSelector
                        validated={validated}
                        disabled={isUpdating || disableFields}
                        selectedDistrict={district}
                        existingMemberCity={existingMemberCity}
                        selectedCity={city}
                        onChangeCity={onChangeCity}
                        setIsChildLoading={setDisableFields}
                        required
                      />
                      <Form.Text>
                        <i className="text-muted">
                          If you cannot find your city, please select the
                          nearest city to you.
                        </i>
                      </Form.Text>
                    </Col>
                  </Row>
                )}
              </Form.Group>
            )}
            <Row className="mt-5 px-3 justify-content-center align-items-center">
              <Col
                className="my-2 w-100 d-flex justify-content-center"
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
              >
                <Button
                  className="w-75 py-1 rounded"
                  variant="outline-primary"
                  disabled={isUpdating || disableFields}
                  onClick={onHide}
                >
                  Skip
                </Button>
              </Col>
              <Col
                className="my-2 w-100 d-flex justify-content-center"
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
              >
                <Button
                  className="w-75 py-1 rounded"
                  variant="primary"
                  type="submit"
                  disabled={
                    isUpdating ||
                    disableFields ||
                    (!isUpdateBirthday && !isUpdateAddress && !email)
                  }
                >
                  Update
                </Button>
              </Col>
            </Row>
            {!isUpdateBirthday && !isUpdateAddress && !email && (
              <Row className="mt-2 px-3 justify-content-center align-items-center">
                <Col
                  className="my-2 w-100 d-flex justify-content-center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <small className="font-weight-bold text-center text-primary">
                    Please enter an email to enable the "Update" button.
                  </small>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

UpdateMissingDataForm.defaultProps = {
  validated: false,
  disableFields: false,
  isUpdating: false,
  dob: PropTypes.string,
  preferredChannel: [],
  isEmailMandatory: false,
  email: "",
  emailValidity: false,
  isEmailVerified: false,
  verifiedEmail: "",
  line1: "",
  allDistricts: [],
  district: [],
  existingMemberCity: "",
  city: [],
  setDisableFields: () => {},
  onSubmitUpdates: () => {},
  onChangeAttr: () => {},
  onChangeDate: () => {},
  onChangePreferredChanel: () => {},
  onChangeDistrict: () => {},
  onChangeCity: () => {},
  onHide: () => {},
};

UpdateMissingDataForm.propTypes = {
  validated: PropTypes.bool,
  disableFields: PropTypes.bool,
  isUpdating: PropTypes.bool,
  dob: PropTypes.string,
  preferredChannel: PropTypes.array,
  isEmailMandatory: PropTypes.bool,
  email: PropTypes.string,
  emailValidity: PropTypes.bool,
  isEmailVerified: PropTypes.bool,
  verifiedEmail: PropTypes.string,
  line1: PropTypes.string,
  allDistricts: PropTypes.array,
  district: PropTypes.array,
  existingMemberCity: PropTypes.string,
  city: PropTypes.array,
  setDisableFields: PropTypes.func,
  onSubmitUpdates: PropTypes.func,
  onChangeAttr: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangePreferredChanel: PropTypes.func,
  onChangeDistrict: PropTypes.func,
  onChangeCity: PropTypes.func,
  onHide: PropTypes.func,
};

export default UpdateMissingDataForm;
