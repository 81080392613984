import React from "react";
import { Image, Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { NavigationData } from "../../data";

import "./PortalMainNavbar.css";

const PortalMainNavbar = () => {
  const location = useLocation();

  return (
    <Navbar className="py-0 mt-4 d-none d-lg-block" expand="lg" id="topNavbar">
      <div className="d-flex flex-column mx-auto">
        {NavigationData.filter((item) => item.id !== "nav-profile").map(
          (navItem) => (
            <Nav.Item key={navItem.id}>
              <Nav.Link
                as={Link}
                href={navItem.path}
                to={navItem.path}
                active={location.pathname === navItem.path}
              >
                <Image
                  alt={navItem.name}
                  height="30"
                  width="30"
                  src={
                    location.pathname === navItem.path
                      ? navItem.selectedicon
                      : navItem.icon
                  }
                />
              </Nav.Link>
            </Nav.Item>
          )
        )}
      </div>
    </Navbar>
  );
};

export default PortalMainNavbar;
