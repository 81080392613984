


import { fetchPost } from './CommonServiceUtils';
import { LOYALTY_SERVICE_URL } from './../Constants';
const BASE_URL = `${LOYALTY_SERVICE_URL}accounts`;

const validateCardNo = payload => {
  return fetchPost(`${BASE_URL}/validatecard`, payload)
};

const getVerifyTokenExisting = payload => {
    return fetchPost(`${BASE_URL}/verifytokenexisting`, payload)
  };


  const verifyAccountOTP = payload => {
    return fetchPost(`${BASE_URL}/verify`, payload)
  };

  const createAccount=payload=>{
    return fetchPost(BASE_URL, payload)
  }
export {validateCardNo,getVerifyTokenExisting,verifyAccountOTP,createAccount};