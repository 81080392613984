const parseQueryParams = (url) => {
  const params = {};
  try {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;

    let match;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }
  } catch (e) {}
  return params;
};

const rewardExpireTimeFormat = (expireOn) => {
  if (expireOn) {
    const diffTime = Math.abs(new Date(expireOn) - new Date());
    const diffInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffInDays > 1 && diffInDays < 20) {
      return diffInDays + " d";
    }

    if (diffInDays > 0 && diffInDays < 1) {
      const diffInHours = Math.ceil(diffTime / (1000 * 60 * 60));
      return diffInHours + " h";
    }
  }
  return null;
};

const rewardExpired = (expireOn) => {
  if (expireOn) {
    const diffTime = Math.abs(new Date(expireOn) - new Date());
    const diffInHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffInHours <= 0) {
      return true;
    }
  }
  return false;
};
export { parseQueryParams, rewardExpireTimeFormat, rewardExpired };
