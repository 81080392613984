const getHeaders = async (isAuth, headerList = {}) => {
  const headers = { "Content-Type": "application/json", ...headerList };
  if (isAuth) {
    headers["x-auth"] = true;
  }
  return headers;
};

const handleErrors = async (response) => {
  try {
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return response;
  } catch (error) {
    try {
      return Promise.reject(await response.json());
    } catch (err) {
      return Promise.reject({ error: "Internal issue" });
    }
  }
};

const parseJson = (response) => {
  try {
    return response.json();
  } catch (e) {
    console.error("Cannot parse:", response);
    return Promise.reject({ error: "Internal issue" });
  }
};

const fetchGet = async (url, isAuth = true) => {
  return fetch(url, { headers: await getHeaders(isAuth) })
    .then(handleErrors)
    .then(parseJson);
};

const fetchPost = async (url, body, isAuth = true) => {
  return fetch(url, {
    headers: await getHeaders(isAuth),
    method: "POST",
    body: JSON.stringify(body),
  })
    .then(handleErrors)
    .then(parseJson);
};

const fetchPostFormData = async (url, body, isAuth = true) => {
  return fetch(url, {
    headers: await getHeaders(isAuth, {
      "Content-Type": "application/x-www-form-urlencoded",
    }),
    method: "POST",
    body: new URLSearchParams([...Object.entries(body)]),
  })
    .then(handleErrors)
    .then(parseJson);
};

const fetchPut = async (url, body, isAuth = true) => {
  return fetch(url, {
    method: "PUT",
    headers: await getHeaders(isAuth),
    body: JSON.stringify(body),
  })
    .then(handleErrors)
    .then(parseJson);
};

const valueIsArr = (result, key, value) => {
  value.forEach((element) => {
    result += `${result ? "&" : ""}${key}[]=${element}`;
  });

  return result;
};

const jsonToQueryParam = (json) => {
  let result = "";

  if (json) {
    Object.entries(json).forEach(([key, value]) => {
      if (value !== "" && value != null) {
        if (Array.isArray(value)) {
          result = valueIsArr(result, key, value);
        } else {
          result = result + `${result ? "&" : ""}${key}=${value}`;
        }
      }
    });
  }

  return result;
};

export { fetchGet, fetchPost, fetchPostFormData, fetchPut, jsonToQueryParam };
