import React from "react";
import LoadingSpinner from "../../utils/loadingSpinner/LoadingSpinner";

const PnSLoadingSpinner = ({
  className = "",
  text = null,
  spinnerProps = {},
}) => (
  <div className={`text-pns ${className}`}>
    <LoadingSpinner {...spinnerProps} /> {text || "Please wait..."}
  </div>
);

export default PnSLoadingSpinner;
