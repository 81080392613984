import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { Hint } from "react-bootstrap-typeahead";

import ClearButton from "./ClearButton";

const SelectSingleInput = ({
  inputRef,
  referenceElementRef,
  size,
  shouldSelectHint,
  selectedValue,
  clearButton,
  onClickClear,
  readonly,
  onBlur,
  required,
  additionalClasses = "",
  ...inputProps
}) => (
  <Hint shouldSelect={shouldSelectHint}>
    <Fragment>
      <Form.Control
        autoComplete="null"
        {...inputProps}
        ref={(node) => {
          inputRef(node);
          referenceElementRef(node);
        }}
        className={`custom-select ${size ? "form-control-" + size : ""} border rounded ${additionalClasses}`}
        readOnly={readonly}
        onBlur={onBlur}
        required={required}
      />
      {clearButton && <ClearButton onClick={onClickClear} />}
    </Fragment>
  </Hint>
);

export default SelectSingleInput;
