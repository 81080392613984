const RewardTabs = {
    ALL_REWARDS: "ALL_REWARDS",
    MY_REWARDS: "MY_REWARDS"
};

const ValidityPeriod = {
    OPEN: "OPEN",
    FIXED: "FIXED"
};

const RewardsProcessingStatus = {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    DISPATCHED: "DISPATCHED",
    COMPELTED: "COMPLETED",
    FAILED: "FAILED"
};

const RewardStatusData = {
    REQUESTED : "REQUESTED",
    READY : "READY",
    CANCELLED : "CANCELLED",
    CLAIMED :  "CLAIMED",
}

export { RewardTabs, ValidityPeriod, RewardsProcessingStatus, RewardStatusData };