import React from "react";
import { Dropdown } from "react-bootstrap";
import { Calendar } from "react-date-range";
import moment from "moment";
import PropTypes from "prop-types";

import "react-date-range/dist/styles.css"; // * Main style file
import "react-date-range/dist/theme/default.css"; // * Theme css file
import "./FormDate.css";

const FormDate = ({
  className,
  selectText,
  size,
  date,
  dateDisplayFormat,
  disabled,
  ...rest
}) => {
  return (
    <Dropdown className="form-date">
      <Dropdown.Toggle
        size={size}
        id="dropdown-form-date"
        className={`font-weight-light w-100 form-control ${
          date ? "text-dark" : "placeholder"
        } ${size ? `form-control-${size}` : ""} ${className}`}
        disabled={disabled}
      >
        {date ? moment(date).format(dateDisplayFormat) : selectText}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Calendar {...(date ? { date, ...rest } : rest)} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

FormDate.defaultProps = {
  color: "#004b78",
  selectText: "Date",
  direction: "vertical",
  showMonthAndYearPickers: true,
  showSelectionPreview: true,
  months: 1,
  disabledDates: [],
  dateDisplayFormat: "MMM DD, yyyy",
  date: new Date(),
  className: "",
};

FormDate.propTypes = {
  selectText: PropTypes.string, // * Selection dropdown text
  size: PropTypes.oneOf(["sm", "default"]), //* Selection dropdown size
  disabled: PropTypes.bool, //* Disable/enable
  color: PropTypes.string,
  locale: PropTypes.object, //* Default en-us. refer https://www.npmjs.com/package/react-date-range
  className: PropTypes.string, //* Wrapper classname
  months: PropTypes.number, //* Rendered month count
  showSelectionPreview: PropTypes.bool, //* Show preview on focused/hovered dates
  showMonthAndYearPickers: PropTypes.bool, //* Show select tags for month and year on calendar top, if false it will just display the month and year
  rangeColors: PropTypes.array, //* Defines color for selection preview.
  shownDate: PropTypes.instanceOf(Date), //* Initial focus date
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  disabledDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  date: PropTypes.instanceOf(Date), //* Date value for Calendar
  onChange: PropTypes.func.isRequired, //* Callback function for date changes. fn(date: Date)
};

export default FormDate;
