import React, { Fragment, useCallback, useRef, useState } from "react";
import {
  Typeahead,
  Highlighter,
  Menu,
  MenuItem,
} from "react-bootstrap-typeahead";
import PropTypes from "prop-types";
import SelectSingleInput from "./SelectSingleInput";

import "./FormSelect.css";

const FormSelect = ({
  size,
  groupBy,
  labelKey = "label",
  selected = [],
  options,
  onChange,
  clearButton,
  noBorder,
  readonly,
  onBlur,
  required,
  additionalClasses,
  ...rest
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const ref = useRef();

  const defaultFilter = useCallback(
    (option, props) => {
      if (isDirty) {
        if (!props.text) {
          return true;
        }

        return (
          option[props.labelKey] &&
          option[props.labelKey]
            .toLowerCase()
            .indexOf(props.text.toLowerCase()) !== -1
        );
      }

      return true;
    },
    [isDirty]
  );

  const onInputChange = useCallback(() => {
    if (!isDirty) {
      setIsDirty(true);
    }
  }, [setIsDirty, isDirty]);

  const onClickClear = useCallback(() => {
    onChange([]);
  }, [onChange]);
  return (
    <Typeahead
      className={`select-typeahead ${noBorder ? "no-border" : ""}`}
      labelKey={labelKey}
      onInputChange={onInputChange}
      filterBy={defaultFilter} // * Show menu open when click regardless of value already selected or not
      options={options}
      onChange={onChange}
      selected={selected}
      clearButton={false}
      readonly={readonly}
      {...rest}
      ref={ref}
      renderMenu={(results, menuProps, state) => {
        let index = 0;
        let transformedItemObject;
        if (groupBy) {
          transformedItemObject = results.reduce((r, item) => {
            const key = item[groupBy] || "~";
            if (r[key]) {
              r[key].push(item);
            } else {
              r[key] = [item];
            }
            return r;
          }, {});
        } else {
          transformedItemObject = { "~": results };
        }

        const items = Object.keys(transformedItemObject)
          .sort()
          .map((item) => (
            <Fragment key={item}>
              {item !== "~" ? (
                <Menu.Header>{item !== "~" ? item : " "}</Menu.Header>
              ) : index !== 0 ? (
                <Menu.Divider />
              ) : null}
              {transformedItemObject[item].map((i, keyIndex) => {
                const isSelected = selected.find(
                  (item) => item[labelKey] === i[labelKey]
                );
                const item = (
                  <MenuItem
                    key={index}
                    option={i}
                    position={index}
                    className={`${isSelected ? "selected" : ""}`}
                    onClick={() => {}}
                    data-optionkey={i[labelKey]}
                  >
                    <Highlighter search={state ? state.text : ""}>
                      {typeof i === "object" ? i[labelKey] : i}
                    </Highlighter>
                    <span className="menu-item-icon">✓</span>
                  </MenuItem>
                );

                index += 1;
                return item;
              })}
            </Fragment>
          ));

        return readonly ? null : <Menu {...menuProps}>{items}</Menu>;
      }}
      renderInput={(props) => {
        return (
          <SelectSingleInput
            {...props}
            selectedValue={selected}
            size={size}
            clearButton={clearButton}
            onClickClear={onClickClear}
            readonly={readonly}
            onBlur={onBlur}
            required={required}
            additionalClasses={additionalClasses}
          />
        );
      }}
    />
  );
};

FormSelect.defaultProps = {
  noBorder: false,
  onBlur: () => {},
  required: false,
};

FormSelect.propTypes = {
  /**group items based on attribute */
  groupBy: PropTypes.string,

  /** selected values */
  selected: PropTypes.array.isRequired,

  /**onChange value */
  onChange: PropTypes.func.isRequired,

  /**Border less */
  noBorder: PropTypes.bool,

  /**onBlur input */
  onBlur: PropTypes.func,

  /**
   * required
   */
  required: PropTypes.bool,
};

export default FormSelect;
