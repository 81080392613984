import {
  fetchGet,
  fetchPost,
  fetchPut,
  jsonToQueryParam,
} from "./CommonServiceUtils";
import { PNS_SYNC_SERVICE_URL } from "./../Constants";

const getRegionData = (queryObj) =>
  fetchGet(`${PNS_SYNC_SERVICE_URL}regions?${jsonToQueryParam(queryObj)}`);

const getLocations = (queryObj) =>
  fetchGet(`${PNS_SYNC_SERVICE_URL}locations?${jsonToQueryParam(queryObj)}`);

const filterLoyaltyProfileByMobileNumber = (queryObj, payload) =>
  fetchPost(
    `${PNS_SYNC_SERVICE_URL}member/filterbymobile?${jsonToQueryParam(
      queryObj
    )}`,
    payload
  );

const enrolNewMember = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}member`, payload);

const enrolExistingMember = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}member/existing`, payload);

const verifyAccountCreation = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}account/verify`, payload);

const resendVerifyAccountCreationToken = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}account/resendtoken`, payload);

const createPortalAccount = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}account`, payload);

const updatePortalAccount = (payload = {}) =>
  fetchPut(`${PNS_SYNC_SERVICE_URL}member`, payload);

const sendVerificationOtpToMobileNumber = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}mobile/requestotp`, payload);

const verifyMobileNumberWithOtp = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}mobile/verify`, payload);

const sendVerificationOtpToEmail = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}email/requestotp`, payload);

const verifyEmailWithOtp = (payload) =>
  fetchPost(`${PNS_SYNC_SERVICE_URL}email/verify`, payload);

const getAllSriLankanDistricts = () =>
  fetchGet(`${PNS_SYNC_SERVICE_URL}sl/geographics/districts`);

const getAllSriLankanCities = () =>
  fetchGet(`${PNS_SYNC_SERVICE_URL}sl/geographics/cities`);

const getAllSriLankanCitiesByDistrict = (district = "") =>
  fetchGet(`${PNS_SYNC_SERVICE_URL}sl/geographics/cities/${district}`);

const getAllLocations = (params) => {
  return new Promise(async (resolve, reject) => {
    const limit = 500;
    let totalCount = 0,
      locations = [];
    try {
      do {
        const locationsResponse = await getLocations({
          limit,
          skip: locations.length,
          ...params,
        });
        totalCount = locationsResponse.total;
        locations = [...locations, ...locationsResponse.items];
      } while (locations.length < totalCount);

      const enrolLocations = locations
        .filter(
          (location) =>
            !isNaN(Number(location.code)) && // * To temporarily filter out locations without a number for the location code.
            location?.options?.enroll
        )
        .map((eLocation) => ({
          _id: eLocation?._id || "",
          locationName: eLocation?.locationName || "~ unknown",
          locationCode: eLocation?.code || "~ unknown",
        }));

      resolve(enrolLocations);
    } catch (e) {
      reject(e);
    }
  });
};

export {
  filterLoyaltyProfileByMobileNumber,
  getRegionData,
  getAllLocations,
  enrolNewMember,
  enrolExistingMember,
  verifyAccountCreation,
  resendVerifyAccountCreationToken,
  createPortalAccount,
  updatePortalAccount,
  sendVerificationOtpToMobileNumber,
  verifyMobileNumberWithOtp,
  sendVerificationOtpToEmail,
  verifyEmailWithOtp,
  getAllSriLankanDistricts,
  getAllSriLankanCities,
  getAllSriLankanCitiesByDistrict,
};
