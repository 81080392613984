import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { getAllSriLankanCitiesByDistrict } from "../../../services";
import FormSelect from "../formSelect/FormSelect";
import PnSLoadingSpinner from "../pnsLoadingSpinner/PnSLoadingSpinner";
import { DataContext } from "../../../contexts";

const DistrictSelector = ({
  validated,
  disabled,
  allDistricts,
  selectedDistrict,
  required,
  setIsChildLoading,
  onChangeDistrict,
}) => {
  DistrictSelector.defaultProps = {
    validated: false,
    disabled: false,
    allDistricts: [],
    selectedDistrict: [],
    required: false,
    setIsChildLoading: () => {},
    onChangeDistrict: () => {},
  };

  DistrictSelector.propTypes = {
    validated: PropTypes.bool,
    disabled: PropTypes.bool,
    allDistricts: PropTypes.array,
    selectedDistrict: PropTypes.array,
    required: PropTypes.bool,
    setIsChildLoading: PropTypes.func,
    onChangeDistrict: PropTypes.func,
  };

  const { isLoadingAllSLDistricts, loadAllSriLankanDistricts } =
    useContext(DataContext);

  const reloadDistricts = useCallback(async () => {
    setIsChildLoading(true);
    await loadAllSriLankanDistricts();
    setIsChildLoading(false);
  }, [setIsChildLoading, loadAllSriLankanDistricts]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Form.Label>
          District
          {required ? <span className="text-danger">*</span> : null}
        </Form.Label>
        {isLoadingAllSLDistricts ? (
          <PnSLoadingSpinner
            className="mb-2 d-flex align-items-center"
            text={
              <div>
                <small>
                  <i>Loading all districts...</i>
                </small>
              </div>
            }
            spinnerProps={{ className: "mr-1", size: "sm" }}
          />
        ) : (
          <Button
            className="mb-2 p-0"
            variant="link"
            size="sm"
            disabled={disabled || isLoadingAllSLDistricts}
            onClick={reloadDistricts}
          >
            <i>Reload</i>
          </Button>
        )}
      </div>
      <FormSelect
        id="district"
        placeholder={
          allDistricts.length === 0
            ? "No districts found."
            : "Select your district..."
        }
        options={allDistricts}
        selected={selectedDistrict}
        onChange={onChangeDistrict}
        disabled={disabled || isLoadingAllSLDistricts}
        required={required}
      />
      {required && validated && selectedDistrict.length === 0 ? (
        <small className="text-danger">District cannot be empty!</small>
      ) : null}
    </>
  );
};

const CitiesOfDistrictSelector = ({
  validated,
  disabled,
  selectedDistrict,
  existingMemberCity,
  selectedCity,
  required,
  setIsChildLoading,
  onChangeCity,
}) => {
  CitiesOfDistrictSelector.defaultProps = {
    validated: false,
    disabled: false,
    selectedDistrict: [],
    existingMemberCity: "",
    selectedCity: [],
    required: false,
    setIsChildLoading: () => {},
    onChangeCity: () => {},
  };

  CitiesOfDistrictSelector.propTypes = {
    validated: PropTypes.bool,
    disabled: PropTypes.bool,
    selectedDistrict: PropTypes.array,
    existingMemberCity: PropTypes.string,
    selectedCity: PropTypes.array,
    required: PropTypes.bool,
    setIsChildLoading: PropTypes.func,
    onChangeCity: PropTypes.func,
  };

  const [allCitiesOfDistrict, setAllCitiesOfDistrict] = useState([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);

  const loadAllSriLankanCitiesOfDistrict = useCallback(async () => {
    try {
      if (selectedDistrict.length === 0 || !selectedDistrict[0]?.value)
        throw new Error("District cannot be empty, please select a district");

      setAllCitiesOfDistrict([]);
      setIsChildLoading(true);
      setIsLoadingCities(true);
      const allSLCitiesData = await getAllSriLankanCitiesByDistrict(
        selectedDistrict[0].value
      );

      const mappedAllSLCitiesData = allSLCitiesData?.items.map((aSLC) => ({
        label: `${aSLC?.name_en || "~ unknown"}${
          aSLC?.name_si ? "/" + aSLC.name_si : ""
        }${aSLC?.name_ta ? "/" + aSLC.name_ta : ""}`,
        value: aSLC?.name_en,
        id: aSLC?.id || "",
      }));
      if (mappedAllSLCitiesData.length !== 0) {
        setAllCitiesOfDistrict(mappedAllSLCitiesData);
      } else {
        setAllCitiesOfDistrict(selectedDistrict);
      }
      setIsChildLoading(false);
      setIsLoadingCities(false);
    } catch (e) {
      console.error(e);
      setAllCitiesOfDistrict([]);
      setIsChildLoading(false);
      setIsLoadingCities(false);
      toast.error(
        <div>
          Failed to load cities for the district "
          {selectedDistrict[0]?.value || "~ unknown"}"!
          <br />
          {e.error ? `Error: ${e.error}` : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    }
  }, [selectedDistrict, setIsChildLoading, setAllCitiesOfDistrict]);

  useEffect(() => {
    loadAllSriLankanCitiesOfDistrict();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedCity.length !== 0) {
      onChangeCity(selectedCity);
    } else if (allCitiesOfDistrict.length !== 0) {
      const existingMemberCityObject = allCitiesOfDistrict.filter(
        (aCOD) => aCOD?.value === existingMemberCity
      );
      onChangeCity(existingMemberCityObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCitiesOfDistrict, existingMemberCity]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Form.Label>
          City
          {required ? <span className="text-danger">*</span> : null}
        </Form.Label>
        {isLoadingCities ? (
          <PnSLoadingSpinner
            className="mb-2 d-flex align-items-center"
            text={
              <div>
                <small>
                  <i>{`Loading all cities for ${
                    selectedDistrict[0]?.value || "district"
                  }...`}</i>
                </small>
              </div>
            }
            spinnerProps={{ className: "mr-1", size: "sm" }}
          />
        ) : (
          <Button
            className="mb-2 p-0"
            variant="link"
            size="sm"
            disabled={disabled || isLoadingCities}
            onClick={loadAllSriLankanCitiesOfDistrict}
          >
            <i>Reload</i>
          </Button>
        )}
      </div>
      <FormSelect
        id="city"
        placeholder="Select your city or nearest city..."
        options={allCitiesOfDistrict}
        selected={selectedCity}
        onChange={onChangeCity}
        disabled={disabled || isLoadingCities}
        required={required}
      />
      {required && validated && selectedCity.length === 0 ? (
        <small className="text-danger">City cannot be empty!</small>
      ) : null}
    </>
  );
};

export { DistrictSelector, CitiesOfDistrictSelector };
