const PointRuleSubTypes = {
   BIRTHDAY: "BIRTHDAY",
   ENROLL: "ENROLL",
   SIGNUP: "SIGNUP"
};

const RuleState = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
};

export { PointRuleSubTypes, RuleState };