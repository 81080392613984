import { toTitleCase } from "./StringUtils";

const objectToKeyValueArray = (items, isMultipleObj) => {
    const newArr = Object.keys(items);
    if(isMultipleObj) {
        return newArr.map(item => {
            return {
                "key": item,
                "value": toTitleCase(items[item].filterValue)
            };
        });
    }
    else {
        return newArr.map(item => {
            return {
                "key": item,
                "value": toTitleCase(items[item])
            };
        });
    };
};

export { objectToKeyValueArray };