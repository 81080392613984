import moment from "moment";

const formatToCommonReadableFormat = (date) =>
  moment(date).format("MMM DD, YYYY LT");

const formatToCommonReadableFormatDateOnly = (date) =>
  moment(date).format("MMM DD, YYYY");

const formatToYearMonthDate = (date) => moment(date).format("YYYY-MM-DD");

const secondsToMinuteAndSeconds = (seconds) => {
  const m = Math.floor((seconds % 3600) / 60).toString(),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");
  return `${m}:${s}`;
};

const isValidDate = (dateToValidate = "") => moment(dateToValidate).isValid();

export {
  formatToCommonReadableFormat,
  formatToCommonReadableFormatDateOnly,
  formatToYearMonthDate,
  secondsToMinuteAndSeconds,
  isValidDate,
};
