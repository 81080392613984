import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import i18n from "./i18n";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import * as serviceWorker from "./serviceWorker";

import "react-intl-tel-input/dist/main.css";

const noop = () => {};
if (process.env && process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.debug = noop;
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
