const Translations = {
    si: {
      translation: {
        "A little Loyalty": "පොඩි විශ්වාසයකින්",
        "goes a long way" : "දිගු ගමනක්", 
        "Introducing P&S’ Family First Loyalty Program, rewarding you and your family for your continued patronage. " : "P&S Family First Loyalty ඔබව  සහ ඔබගේ පවුලේ  සියලුම දෙනාවම සතුටට පත් කරන හොදම ක්‍රමය. ",
        "Terms of Use" : "භාවිත නියම",
        "Login" : "පිවිසෙන්න",
        "Don't have an account?" : "ගිණුමක් නැද්ද?",
        "Already have an account?" : "දැනටමත් ගිණුමක් තිබේද?",
        "Sign Up" :  "ලියාපදිංචි වන්න",
        "Join Now" :  "දැන්ම එකතු වන්න",
        "Getting Started is Easy!" : "ආරම්භ කිරීම පහසුය!",
        "Join our family and start earning points": "අප හා එක්ව Points උපයා ගැනීම ආරම්භ කරන්න",
        "Join Us" : "අප හා එක්වන්න",
        "To join us, visit one of our selected P&S outlets, and get yourself registered to our P&S Family First Loyalty programme and get access to join our online portal" : "අප හා එක්වීමට අදම  ඔබගේ  ලගම පිහිටි P&S  ශාඛාවට පිවිස අපගේ  P&S Family First Loyalty වැඩසටහනට ලියාපදිංචි වී අපගේ online portal එකට ප්‍රවේශය ලබා ගන්න.",
        "Earn Points" : "Points උපයන්න",
        "To earn Family Points, you can make purchases at selected P&S outlets and earn 1 point for every Rs. 100 that you spend" : "Family Points උපයා ගැනීම සඳහා, ඔබට තෝරාගත් P&S අලෙවිසැල් වලින් මිලදී ගැනීම් කළ හැකි අතර ඔබ වියදම් කරන සෑම රු. 100 කටම ලකුණු 1 ක් උපයාගත හැක.",
        "Redeem Points" : "Points මුදා ගන්න",
        "You can redeem your Family Points against the bill value for any purchase done at selected P&S outlets. More rewards are coming soon..." : "තෝරාගත් P&S අලෙවිසැල් වලින් කරන ඕනෑම මිලදී ගැනීමක් සඳහා බිල් වටිනාකමට සාපේක්ෂව ඔබේ Family First ලකුණු මුදවා ගත හැකිය. තවත් අලුත් තෑගි බලාපොරොත්තු වන්න."
      }
    }, 
    en: {
      translation: {
        "A little Loyalty": "A little Loyalty",
        "goes a long way" : "goes a long way",
        "Introducing P&S’ Family First Loyalty Program, rewarding you and your family for your continued patronage. " : "Introducing P&S’ Family First Loyalty Program, rewarding you and your family for your continued patronage. ",
        "Terms of Use" : "Terms of Use",
        "Login" : "Login",
        "Don't have an account?" : "Don't have an account?",
        "Already have an account?" : "Already have an account?",
        "Sign Up" :  "Sign Up",
        "Join Now" :  "Join Now",
        "Getting Started is Easy!" : "Getting Started is Easy!",
        "Join our family and start earning points": "Join our family and start earning points",
        "Join Us" : "Join Us",
        "To join us, visit one of our selected P&S outlets, and get yourself registered to our P&S Family First Loyalty programme and get access to join our online portal" : "To join us, visit one of our selected P&S outlets, and get yourself registered to our P&S Family First Loyalty programme and get access to join our online portal",
        "Earn Points" : "Earn Points",
        "To earn Family Points, you can make purchases at selected P&S outlets and earn 1 point for every Rs. 100 that you spend" : "To earn Family Points, you can make purchases at selected P&S outlets and earn 1 point for every Rs. 100 that you spend",
        "Redeem Points" : "Redeem Points",
        "You can redeem your Family Points against the bill value for any purchase done at selected P&S outlets. More rewards are coming soon..." : "You can redeem your Family Points against the bill value for any purchase done at selected P&S outlets. More rewards are coming soon..."
      }
    }
};

export default Translations;
