import React from "react";

/**
 * ClearButton
 *
 * http://getbootstrap.com/css/#helper-classes-close
 */
const ClearButton = ({ className, label, onClick, ...props }) => (
  <button
    {...props}
    aria-label={label}
    className="close rbt-close clear-button"
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
    type="button"
  >
    <span aria-hidden="true">&times;</span>
    <span className="sr-only visually-hidden">{label}</span>
  </button>
);

export default ClearButton;
