const sriLankanMobileValidationString =
  /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

const toTitleCase = (text) => {
  if (text) {
    const str = text?.replace(/[-_]/g, " ").trim();
    let upper = true;
    let newStr = "";

    for (let i = 0, l = str.length; i < l; i++) {
      if (str[i] === " ") {
        upper = true;
        newStr += " ";
        continue;
      }
      newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
      upper = false;
    }
    return newStr;
  }

  return text;
};

const replaceCountryCodeInMobileNumber = (
  mobileNumber,
  replaceCharCount = 0,
  replaceWith = ""
) => {
  const replaceRegex = new RegExp(`^.{${replaceCharCount || 2}}`, "g");
  return mobileNumber?.replace(replaceRegex, replaceWith || "0");
};

export {
  sriLankanMobileValidationString,
  toTitleCase,
  replaceCountryCodeInMobileNumber,
};
