import React, {
  useReducer,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import { RuleState } from "../data";
import {
  getOrganizationsConfig,
  getRegionsConfig,
  getLocations,
  getTiers,
  getPointRules,
  getRegionData,
  getAllLocations,
  getAllSriLankanDistricts,
} from "../services";
import { KeycloakContext } from "./KeycloakContext";

const defaultLimit = 1000,
  defaultSkip = 0;

const initialState = {
  isLoadingRegionsData: false,
  isLoadingLocations: false,
  isLoadingOrganizationsConfig: false,
  isLoadingRegionsConfig: false,
  isLoadingTiers: false,
  isLoadingPointRules: false,
  isLoadingPickupLocations: false,
  region: {
    defaultCountryISO2Code: "LK",
  },
  isLoadingAllSLDistricts: false,
  allSLDistricts: [],
  allSLDistrictsCount: 0,
  allLocations: [],
  organizationsConfig: {},
  regionsConfig: {},
  tiers: [],
  pointRules: [],
  pickupLocations: [],
  /* // TODO: Announcements will be implemented in the future
    announcements: [],
    isLoadingAnnouncements: false */
};

const DataContextActions = {
  SET_IS_LOADING_REGIONS_DATA: "setIsLoadingRegionsData",
  SET_REGIONS_DATA: "setRegionsData",
  SET_IS_LOADING_ALL_SL_DISTRICTS_DATA: "setIsLoadingAllSlDistrictsData",
  SET_ALL_SL_DISTRICTS_DATA: "setAllSlDistrictsData",
  SET_IS_LOADING_LOCATIONS_DATA: "setIsLoadingLocationsData",
  SET_LOCATIONS_DATA: "setLocationsData",
  SET_IS_LOADING_ORGANIZATIONS_CONFIG: "SET_IS_LOADING_ORGANIZATIONS_CONFIG",
  SET_IS_LOADING_REGIONS_CONFIG: "SET_IS_LOADING_REGIONS_CONFIG",
  SET_IS_LOADING_TIERS: "SET_IS_LOADING_TIERS",
  SET_IS_LOADING_POINT_RULES: "SET_IS_LOADING_POINT_RULES",
  SET_IS_LOADING_PICKUP_LOCATIONS: "SET_IS_LOADING_PICKUP_LOCATIONS",
  SET_REGIONS_CONFIG: "SET_REGIONS_CONFIG",
  SET_ORGANIZATIONS_CONFIG: "SET_ORGANIZATIONS_CONFIG",
  SET_TIERS_DATA: "SET_TIERS_DATA",
  SET_POINT_RULES: "SET_POINT_RULES",
  SET_PICKUP_LOCATIONS: "SET_PICKUP_LOCATIONS",
  /* // TODO: Announcements will be implemented in the future
        TOGGLE_LOADING_ANNOUNCEMENTS: "TOGGLE_LOADING_ANNOUNCEMENTS",
        RECEIVED_ANNOUNCEMENTS: "RECEIVED_ANNOUNCEMENTS" */
};

const DataContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case DataContextActions.SET_IS_LOADING_REGIONS_DATA: {
      return { ...state, isLoadingRegionsData: action.loadingState };
    }
    case DataContextActions.SET_REGIONS_DATA: {
      return {
        ...state,
        region: { ...action.data },
      };
    }
    case DataContextActions.SET_IS_LOADING_ALL_SL_DISTRICTS_DATA: {
      return { ...state, isLoadingAllSLDistricts: action.loadingState };
    }
    case DataContextActions.SET_ALL_SL_DISTRICTS_DATA: {
      return {
        ...state,
        allSLDistricts: action.data,
        allSLDistrictsCount: action.count,
      };
    }
    case DataContextActions.SET_IS_LOADING_LOCATIONS_DATA: {
      return { ...state, isLoadingLocations: action.loadingState };
    }
    case DataContextActions.SET_LOCATIONS_DATA: {
      return {
        ...state,
        allLocations: action.data,
      };
    }
    case DataContextActions.SET_IS_LOADING_ORGANIZATIONS_CONFIG: {
      return { ...state, isLoadingOrganizationsConfig: action.loadingState };
    }
    case DataContextActions.SET_IS_LOADING_REGIONS_CONFIG: {
      return { ...state, isLoadingRegionsConfig: action.loadingState };
    }
    case DataContextActions.SET_IS_LOADING_TIERS: {
      return { ...state, isLoadingTiers: action.loadingState };
    }
    case DataContextActions.SET_IS_LOADING_POINT_RULES: {
      return { ...state, isLoadingPointRules: action.loadingState };
    }
    case DataContextActions.SET_IS_LOADING_PICKUP_LOCATIONS: {
      return { ...state, isLoadingPickupLocations: action.loadingState };
    }
    case DataContextActions.SET_ORGANIZATIONS_CONFIG: {
      return {
        ...state,
        organizationsConfig: {
          ...action.config,
          tierConfiguration: {
            ...state.organizationsConfig.tierConfiguration,
            ...action.config.tierConfiguration,
          },
        },
      };
    }
    case DataContextActions.SET_REGIONS_CONFIG: {
      return {
        ...state,
        regionsConfig: {
          ...action.config,
          pointConfiguration: {
            ...state.regionsConfig.pointConfiguration,
            ...action.config.pointConfiguration,
          },
        },
      };
    }
    case DataContextActions.SET_TIERS_DATA: {
      return { ...state, tiers: action.tiers };
    }
    case DataContextActions.SET_PICKUP_LOCATIONS: {
      return { ...state, pickupLocations: action.pickupLocations };
    }
    case DataContextActions.SET_POINT_RULES: {
      return { ...state, pointRules: action.pointRules };
    }
    // TODO: Announcements will be implemented in the future
    // case DataContextActions.LOADING_ANNOUNCEMENTS: {
    //     return { ...state, isLoadingAnnouncements: !state.isLoadingAnnouncements };
    // }
    // case DataContextActions.RECEIVED_ANNOUNCEMENTS: {
    //     return { ...state, announcements: action.announcements, isLoadingAnnouncements: false };
    // }
    default: {
      return state;
    }
  }
};

const DataContextProvider = (props) => {
  const { authComplete } = useContext(KeycloakContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const loadRegionData = useCallback(async () => {
    try {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_REGIONS_DATA,
        loadingState: true,
      });
      const regionsData = await getRegionData({ limit: 1, skip: 0 });
      dispatch({
        type: DataContextActions.SET_REGIONS_DATA,
        data: regionsData?.items[0] || {},
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load region data!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    } finally {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_REGIONS_DATA,
        loadingState: false,
      });
    }
  }, [dispatch]);

  const loadAllLocationsData = useCallback(
    async ({ regionId }) => {
      try {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_LOCATIONS_DATA,
          loadingState: true,
        });
        const locationsData = await getAllLocations({ regionId });
        dispatch({
          type: DataContextActions.SET_LOCATIONS_DATA,
          data: locationsData || [],
        });
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            Failed to load locations!
            <br />
            {e.error || e.message
              ? `Error: ${e.error || e.message}`
              : "Please try again later."}
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      } finally {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_LOCATIONS_DATA,
          loadingState: false,
        });
      }
    },
    [dispatch]
  );

  const loadAllSriLankanDistricts = useCallback(async () => {
    try {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_ALL_SL_DISTRICTS_DATA,
        loadingState: true,
      });
      const districtsData = await getAllSriLankanDistricts();
      dispatch({
        type: DataContextActions.SET_ALL_SL_DISTRICTS_DATA,
        data: districtsData?.items || [],
        count: districtsData?.total || 0,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: DataContextActions.SET_ALL_SL_DISTRICTS_DATA,
        data: [],
        count: 0,
      });
      toast.error(
        <div>
          Failed to load all districts of Sri Lanka!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    } finally {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_ALL_SL_DISTRICTS_DATA,
        loadingState: false,
      });
    }
  }, [dispatch]);

  const loadOrganizationsConfig = useCallback(async () => {
    try {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_ORGANIZATIONS_CONFIG,
        loadingState: true,
      });
      const organizationsConfigRes = await getOrganizationsConfig();
      dispatch({
        type: DataContextActions.SET_ORGANIZATIONS_CONFIG,
        config: organizationsConfigRes,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load organization configurations!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    } finally {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_ORGANIZATIONS_CONFIG,
        loadingState: false,
      });
    }
  }, [dispatch]);

  const loadRegionsConfig = useCallback(async () => {
    try {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_REGIONS_CONFIG,
        loadingState: true,
      });
      const regionsConfigRes = await getRegionsConfig();
      dispatch({
        type: DataContextActions.SET_REGIONS_CONFIG,
        config: regionsConfigRes,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load region configurations!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    } finally {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_REGIONS_CONFIG,
        loadingState: false,
      });
    }
  }, [dispatch]);

  const loadTiers = useCallback(
    async ({ limit, skip }) => {
      const payload = { limit, skip };

      try {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_TIERS,
          loadingState: true,
        });

        const tiersResponse = await getTiers(payload);
        const tiers = tiersResponse.items.sort((a, b) => a.points - b.points);

        dispatch({ type: DataContextActions.SET_TIERS_DATA, tiers });
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            Failed to load tiers!
            <br />
            {e.error || e.message
              ? `Error: ${e.error || e.message}`
              : "Please try again later."}
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      } finally {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_TIERS,
          loadingState: false,
        });
      }
    },
    [dispatch]
  );

  const loadPointRules = useCallback(
    async ({ limit, skip }) => {
      const queryObj = { limit, skip };

      try {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_POINT_RULES,
          loadingState: true,
        });
        const pointRulesResponse = await getPointRules(queryObj);
        const pointRules = pointRulesResponse.items.filter(
          (pR) => pR?.ruleState === RuleState.ACTIVE
        );

        dispatch({ type: DataContextActions.SET_POINT_RULES, pointRules });
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            Failed to load point rules!
            <br />
            {e.error || e.message
              ? `Error: ${e.error || e.message}`
              : "Please try again later."}
            <br />
            <small>If the issue persists, please contact P&S.</small>
          </div>
        );
      } finally {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_POINT_RULES,
          loadingState: false,
        });
      }
    },
    [dispatch]
  );

  const loadPickupLocations = useCallback(async ({ limit, skip }) => {
    const payload = { limit, skip };

    try {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_PICKUP_LOCATIONS,
        loadingState: true,
      });

      const locationsRes = await getLocations(payload);
      const pickupLocations =
        locationsRes?.items.filter((location) => location?.isPickupLocation) ||
        [];

      dispatch({
        type: DataContextActions.SET_PICKUP_LOCATIONS,
        pickupLocations,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load locations!
          <br />
          {e.error || e.message
            ? `Error: ${e.error || e.message}`
            : "Please try again later."}
          <br />
          <small>If the issue persists, please contact P&S.</small>
        </div>
      );
    } finally {
      dispatch({
        type: DataContextActions.SET_IS_LOADING_PICKUP_LOCATIONS,
        loadingState: false,
      });
    }
  }, []);

  useEffect(() => {
    loadRegionData();
    loadAllSriLankanDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.region._id) {
      loadAllLocationsData({ regionId: state.region._id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.region._id]);

  useEffect(() => {
    if (authComplete) {
      loadOrganizationsConfig();
      loadRegionsConfig();
      loadTiers({ limit: defaultLimit, skip: defaultSkip });
      loadPointRules({ limit: defaultLimit, skip: defaultSkip });
      loadPickupLocations({ limit: defaultLimit, skip: defaultSkip });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authComplete]);

  const value = useMemo(
    () => ({
      ...state,
      loadAllLocationsData,
      loadAllSriLankanDistricts,
      loadPickupLocations,
    }),
    [
      state,
      loadAllLocationsData,
      loadAllSriLankanDistricts,
      loadPickupLocations,
    ]
  );

  return (
    <DataContext.Provider value={value}>{props.children}</DataContext.Provider>
  );
};

export { DataContext, DataContextProvider };
