import React, { useCallback, useContext } from "react";
import { Button, Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts";
import { NavigationData } from "../../../data";

import "./PortalHeader.css";

const PortalHeader = () => {
  const { isLoadingUserProfile, isUpdatingUserProfile, logout } =
    useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToMyProfile = useCallback(
    (e) =>
      navigate(
        `/portal${e.currentTarget.id === "back-to-home" ? "" : "/profile"}`
      ),
    [navigate]
  );

  return (
    <div>
      <div className="d-none d-lg-block">
        <Navbar
          className="justify-content-between"
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
        >
          <Navbar.Brand
            className="d-flex justify-content-start align-items-center ml-3"
            href="/portal"
          >
            <Image
              src="/assets/images/logo1.png"
              alt="logo"
              className="logo-pns"
            />
            <h4 className="mb-0 font-weight-bold mt-1 ml-1 text-primary">
              Family First
            </h4>
          </Navbar.Brand>
          {location.pathname === "/portal/profile" ? (
            <div>
              <Button
                id="back-to-home"
                className="border-none p-0 m-0"
                variant="transparent"
                disabled={isLoadingUserProfile || isUpdatingUserProfile}
                onClick={navigateToMyProfile}
              >
                <Image src="/assets/icons/home.svg" alt="user" width="30" />
              </Button>
            </div>
          ) : (
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  className="p-0"
                  variant="transparent"
                  id="dropdown-basic"
                >
                  <Image src="/assets/icons/user.svg" alt="user" width="30" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-pns border-0" alignRight>
                  <Dropdown.Item onClick={navigateToMyProfile} as={Nav.Link}>
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={isLoadingUserProfile || isUpdatingUserProfile}
                    onClick={logout}
                    as={Nav.Link}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </Navbar>
      </div>
      <div className="d-lg-none">
        <Navbar
          className="justify-content-between"
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
        >
          <Navbar.Brand
            className="d-flex justify-content-start align-items-center ml-3"
            href="/portal"
          >
            <Image
              src="/assets/images/logo1.png"
              alt="logo"
              className="logo-pns"
            />
            <h4 className="mb-0 font-weight-bold mt-1 ml-1 text-primary">
              Family First
            </h4>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav activeKey>
              {NavigationData.map((navItem) => (
                <LinkContainer
                  key={navItem.id}
                  className="my-2"
                  to={navItem.path}
                  {...navItem}
                >
                  <Nav.Link
                    eventKey={navItem.id}
                    className="d-flex align-items-center"
                  >
                    {navItem.icon && (
                      <Image
                        alt={navItem.name}
                        height="25"
                        width="25"
                        src={
                          location.pathname === navItem.path
                            ? navItem.selectedicon
                            : navItem.icon
                        }
                        className="mb-1 mr-2"
                      />
                    )}
                    {navItem.name}
                  </Nav.Link>
                </LinkContainer>
              ))}
            </Nav>
            <hr />
            <div className="mb-2 text-center">
              <Button
                className="w-50 rounded"
                variant="outline-danger"
                disabled={isLoadingUserProfile || isUpdatingUserProfile}
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default PortalHeader;
