const MemberStatus = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  ARCHIVED: "ARCHIVED",
};

const MemberType = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  CHARITY: "CHARITY",
};

const MemberStatusBadge = {
  [MemberStatus.ACTIVE]: "success",
  [MemberStatus.SUSPENDED]: "orange",
  [MemberStatus.ARCHIVED]: "danger",
};

const MemberTypeBadge = {
  [MemberType.PRIMARY]: "primary",
  [MemberType.SECONDARY]: "secondary",
  [MemberType.CHARITY]: "purple",
};

export { MemberStatus, MemberType, MemberStatusBadge, MemberTypeBadge };
