
import { fetchPost, fetchPostFormData } from './CommonServiceUtils';
import { LOYALTY_SERVICE_URL, AUTH_CONFIG } from './../Constants';
const BASE_URL = `${LOYALTY_SERVICE_URL}users/`,
    AUTH_URL = `${AUTH_CONFIG['auth-server-url']}realms/${AUTH_CONFIG['realm']}/protocol/openid-connect/`;

const clientId = "pns-portal";

const login = ({ mobileNumber, password }) => {
    // return Auth.signIn('+' + mobileNumber/*, password*/);
    return fetchPostFormData(`${AUTH_URL}token`, {
        "client_id": clientId,
        "grant_type": "password",
        "scope": "openid",
        "username": mobileNumber,
        "password": password
    }, false)
}


const logout = ({ refreshToken }) => {
    return fetchPostFormData(`${AUTH_URL}logout`, {
        "client_id": clientId,
        "refresh_token": refreshToken
    }, false)
}
const signup = ({ loyaltyNo, mobileNumber, password }) => {

    return fetchPost(`${BASE_URL}createaccountrequest`, { loyaltyId: loyaltyNo, mobileNumber, password }, false)
    // const phoneNumber = '+' + mobileNumber;
    // return Auth.signUp({
    //     username: phoneNumber,
    //     password: password,
    //     attributes: {
    //         phone_number: phoneNumber,   // optional - E.164 number convention
    //         // other custom attributes 
    //     },
    //     clientMetadata: {
    //         loyaltyId: loyaltyNo,
    //         referrerUrl: window.location.origin
    //     }
    // });
}


const confirmSignup = ({ code, token }) => {

    return fetchPost(BASE_URL + "createaccount",
        {
            token: token,
            otpCode: code
        }, false);

}

const resendConfirmSignupCode = (mobileNumber) => {
    return fetchPost(LOYALTY_SERVICE_URL + "otpresend",
        {
            mobileNumber: '+' + mobileNumber
        }, false);
}


// const answerCustomChallenge = (answer, user) => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             // Send the answer to the User Pool
//             // This will throw an error if it"s the 3rd wrong answer
//             await Auth.sendCustomChallengeAnswer(user, answer);

//             // It we get here, the answer was sent successfully,
//             // but it might have been wrong (1st or 2nd time)
//             // So we should test if the user is authenticated now
//             try {
//                 // This will throw an error if the user is not yet authenticated:
//                 resolve(await Auth.currentSession());
//             } catch (e) {
//                 console.error('Apparently the user did not enter the right code', e);
//                 reject({ message: "Invalid code entered. Retry login" });
//             }
//         } catch (e) {
//             console.error('Max retries exceeded', e);
//             reject({ message: "Invalid code entered. Retry login" });
//         }
//     })


// }


const tokenRefresh=(refreshToken)=>{
    return fetchPostFormData(`${AUTH_URL}token`, {
        "client_id": clientId,
        "grant_type": "refresh_token",
        "scope": "openid",
        "refresh_token":refreshToken
    }, false)
}



const forgotPassword = (mobileNumber) => {
    return fetchPost(`${BASE_URL}passwordresetrequest`, { username: mobileNumber }, false);
}

const forgotPasswordSubmit = (token, code, newPassword) => {
    return fetchPost(`${BASE_URL}passwordreset`, { token, otpCode: code, password: newPassword }, false);
}
export { login, resendConfirmSignupCode, confirmSignup, forgotPassword, forgotPasswordSubmit, signup ,logout,tokenRefresh}